import React from 'react'
import DB from '../../db'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import SearchInput from '../SearchInput'
import CreateThingForm from '../CreateThingForm'
import Modal from '../Modal'
import PlusIcon from '../Icons/Plus'

import { Project } from '../../db/types'

const CreateProjectContainer = styled.div`
  padding:
  ${props => props.theme.header.height}px
  ${props => props.theme.grid.grid_32}px;
  height: 100%;

  > div {
    display: flex;
    height: 100%;
  }
`

const CreateProjectContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
`

const CreateProjectContainerOptions = styled.div`
  padding: ${props => props.theme.grid.grid_32}px;
  max-width: 600px;
`

const CreateProjectContainerOption = styled.a`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.grid.grid_32}px;

  &:hover {

    .projectOptionImage {
      background: ${props => props.theme.colors.green_dark};

      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
  }

  .projectOptionImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border: solid 1px ${props => props.theme.colors.green};
    border-radius: ${props => props.theme.borderRadius.default}px;

    svg {
      fill: ${props => props.theme.colors.green};
      width: 14px;
    }
  }

  span {
    display: inline-block;
    margin-left: ${props => props.theme.grid.grid_12}px;
  }
`

const CreateProjectImageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
`

const ProjectImage = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -${props => props.theme.grid.grid_8}px;
  width: 40vw;
  transform: translateY(-50%);
`

interface CreateProjectProps {
  db: DB
}

function CreateProject ({ db }: CreateProjectProps): JSX.Element | null {
  const { id } = useParams()
  const [project, setProject] = React.useState<Project | undefined>(undefined)
  const [preExistingThingValue, setPreExistingThingValue] = React.useState('')
  const [thingList, setThingList] = React.useState<string[]>([])
  const [newThingModalIsHidden, setNewThingModalIsHidden] = React.useState(true)

  const navigate = useNavigate()

  function toggleNewThingModal (): void {
    setNewThingModalIsHidden(newThingModalIsHidden => !newThingModalIsHidden)
  }

  const createAndRedirect = (thingId: number): void => {
    if (project === undefined) {
      return
    }

    db.createProjectSession(project.id, thingId).then(() => {
      db.getProjectSessions().then(projectSessions => {
        const projectSession = projectSessions.at(-1)
        if (projectSession !== undefined) {
          navigate(`/project/${projectSession.id}`)
        }
      }).catch(window.alert)
    }).catch(window.alert)
  }

  React.useEffect(() => {
    if (project === undefined) {
      return
    }

    db.getThing(preExistingThingValue).then(t => {
      if (t !== undefined) {
        createAndRedirect(t.id)
      }
    }).catch(window.alert)

    db.getThings(project.thingDefinition.value).then(things => {
      setThingList(things.map(thing => thing.value))
    }).catch(window.alert)
  }, [db, preExistingThingValue, setThingList, project, newThingModalIsHidden])

  React.useEffect(() => {
    if (id === undefined) {
      return
    }

    const parsedId = parseInt(id, 10)
    if (parsedId === undefined) {
      return
    }

    db.getProject(parsedId).then(project => {
      setProject(project)
    }).catch(window.alert)
  }, [db, id, setProject])

  const onFormSubmit = (): void => {
    if (project === undefined) {
      return
    }

    db.getThings(project.thingDefinition.value).then(things => {
      const newestThing = things.at(-1)
      if (newestThing !== undefined) {
        createAndRedirect(newestThing.id)
      }
    }).catch(window.alert)
  }

  return (
  <CreateProjectContainer>
    {project !== undefined &&
      <div>
        <CreateProjectImageContainer>
          <ProjectImage src="/Items/window.png" />
        </CreateProjectImageContainer>

        <CreateProjectContainerWrap>
          <CreateProjectContainerOptions>
            <h2 className='page-title'>Let's get to work on your {project.thingDefinition.value}</h2>
            {thingList.length !== 0 && <SearchInput
              label={`Use existing ${project.thingDefinition.value}`}
              itemList={thingList}
              placeHolder={`Choose a ${project.thingDefinition.value}`}
              inputValue={preExistingThingValue}
              setInputValue={setPreExistingThingValue}
            />}
            <Modal onClick={toggleNewThingModal} isHidden={newThingModalIsHidden} className="">
              <CreateThingForm
                db={db}
                onFormSubmit={onFormSubmit}
                presetType={project.thingDefinition}
              />
            </Modal>
            <CreateProjectContainerOption onClick={toggleNewThingModal}>
              <div className='projectOptionImage'>
                <PlusIcon />
              </div>
              <span>Add new {project.thingDefinition.value}</span>
            </CreateProjectContainerOption>
          </CreateProjectContainerOptions>
        </CreateProjectContainerWrap>
      </div>
    }
  </CreateProjectContainer>
  )
}

export default CreateProject
