import { createGlobalStyle } from 'styled-components'

const ItemStyles = createGlobalStyle`
  .myHouseListItemImage {

    .clothes.washer & {
      background-image: url(/Items/clothes-washer.png);
    }

    .dishwasher & {
      background-image: url(/Items/dishwasher.png);
    }

    .refrigerator & {
        background-image: url(/Items/refrigerator.png);
    }

    .smoke.detector & {
      background-image: url(/Items/smoke-detector.png);
    }
  }
`

export default ItemStyles
