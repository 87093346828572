export default [{
  question: 'What is HouseIQ?',
  answer: 'Answer'
}, {
  question: 'Why does HouseIQ exist?',
  answer: 'Answer'
}, {
  question: 'Who is HouseIQ for?',
  answer: 'Answer'
}, {
  question: 'Is it free?',
  answer: 'Answer'
}, {
  question: 'Is there a mobile app?',
  answer: 'Answer'
}, {
  question: 'Can I edit my house on another device?',
  answer: 'Answer'
}, {
  question: 'What data do you collect and where is it stored?',
  answer: 'Answer'
}, {
  question: 'Can I export my data?',
  answer: 'Answer'
}, {
  question: 'Can I share my house with someone else?',
  answer: 'Answer'
}, {
  question: 'Is HouseIQ actively maintained? Is there a roadmap?',
  answer: 'Answer'
}]
