export default {
  colors: {
    // Neutral
    white: '#fff',
    black: '#0f0e10',
    black80: 'rgba(0,0,0,0.8)',
    black50: 'rgba(0,0,0,0.5)',
    black25: 'rgba(0,0,0,0.3)',
    black10: 'rgba(0,0,0,0.08)',
    black3: 'rgba(0,0,0,0.03)',

    // Green
    green_dark: '#1c5e5a',
    green: '#267e78', // rgb(38,126,120)
    green_fade: 'rgba(38,126,120,0.08)',
    primary: '#33e',
    purple: '#5f57f6',
    purple_shade: '#524bd5',
    tan: '#f3eada',
    red: '#ee6c4d',
    red_fade: 'rgba(238,108,77,0.1)',
    orange: 'orange',
    shadow: 'rgba(0,0,0,0.03)'
  },
  text: {
    size: {
      small: '14',
      default: '16',
      large: '18'
    }
  },
  shadows: {
    small: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    medium: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    large: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'
  },
  borderColor: {
    soft: 'rgba(0,0,0,0.08)',
    hard: 'rgba(0,0,0,0.5)'
  },
  grid: {
    grid_8: '8',
    grid_12: '12',
    grid_16: '16',
    grid_24: '24',
    grid_32: '32',
    grid_48: '48',
    grid_64: '64'
  },
  borderRadius: {
    default: '8',
    large: '16'
  },
  breakpoints: {
    large: '1400',
    medium: '860',
    small: '660'
  },
  transitions: {
    quick: 'all 120ms cubic-bezier(0.4, 0, 0.2, 1)'
  },
  header: {
    height: '85',
    width: '240'
  },
  mediaQueries: {
    desktop: '1400',
    large: '1200',
    mobile: '768',
    lowHeight: '600'
  }
}
