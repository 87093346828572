import React from 'react'
import styled from 'styled-components'
import Logo from '../Logo/Logo'
import Button from '../Button'
import Accordion from '../Accordion'
import faqs from './faqs'

const Header = styled.header`
  position: fixed;
  background-color: rgba(255,255,255,0.9);
  top: 0;
  display: flex;
  align-items: center;
  height: ${props => props.theme.header.height}px;
  padding: 0 ${props => props.theme.grid.grid_64}px;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 1000;

  svg {
    height: 24px;
    width: auto;
  }

  button {
    align-self: center;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding: 0 ${props => props.theme.grid.grid_32}px;
  }
`

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${props => props.theme.breakpoints.large}px;
  width: 100%;
  margin: 0 auto;
`

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-top: ${props => props.theme.header.height * 2}px;
`

const Section = styled.section`
  overflow: hidden;

  &.intro {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 620px;
      width: 100%;

      @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
        height: auto
      }
    }
  }

  &.faqs {
    padding: ${props => props.theme.grid.grid_64 * 2}px ${props => props.theme.grid.grid_32}px;
    background: ${props => props.theme.colors.black3};

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 920px;
    }
  }

  &.green {
    background: linear-gradient(${props => props.theme.colors.white}, ${props => props.theme.colors.green_fade});
  }

  h2 {
    margin-bottom: ${props => props.theme.grid.grid_32}px;
  }
`

const SectionWrap = styled.div`
  display: flex;
  max-width: ${props => props.theme.breakpoints.large}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${props => props.theme.grid.grid_32}px;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    flex-direction: column-reverse;
    padding: 0 ${props => props.theme.grid.grid_16}px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.grid.grid_64 * 2}px ${props => props.theme.grid.grid_32}px;
  width: 50%;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding: ${props => props.theme.grid.grid_32}px ${props => props.theme.grid.grid_16}px;
    width: 100%;
  }
`

const ProductImage = styled.img`
  position: relative;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.default}px;
  border: solid 1px ${props => props.theme.colors.black10};
`

const GreenText = styled.span`
  color: ${props => props.theme.colors.green_dark};
`

const HeaderSubtext = styled.div`
  font-size: 1.2rem;
  max-width: 600px;
  padding: ${props => props.theme.grid.grid_12}px 0 ${props => props.theme.grid.grid_48}px;
`

const Footer = styled.footer`
  color: ${props => props.theme.colors.black50};
  font-size: ${props => props.theme.text.size.small}px;
  text-align: center;
  padding: ${props => props.theme.grid.grid_48}px ${props => props.theme.grid.grid_32}px;
`

function Landing (): JSX.Element | null {
  return (
    <>
      <Header>
        <HeaderWrap>
          <Logo/>

          <Button
            onClick={() => {
              window.location.href = 'https://app.yourhouseiq.com'
            }}
            text='Open HouseIQ'
            className={''}
          />
        </HeaderWrap>
      </Header>

      <Content>

        <Section className='intro'>
          <SectionWrap>
            <h1>The smarter way to <GreenText>own your home</GreenText></h1>
            <HeaderSubtext>
              HouseIQ brings peace of mind to managing your home.
            </HeaderSubtext>
            <Button
              onClick={() => {
                window.location.href = 'https://app.yourhouseiq.com'
              }}
              text='Get started free'
              className={'large'}
            />
          </SectionWrap>
        </Section>

        <Section className='green'>
          <SectionWrap>
            <Column>
              <ProductImage src="/never-work-alone-again.png" />
            </Column>
            <Column>
              <h3>Never work on a project alone again</h3>
              <div>
                Tutorials for all your projects.
              </div>
            </Column>
          </SectionWrap>
        </Section>

        <Section>
          <SectionWrap>
            <Column>
              <h3>Avoid expensive suprises</h3>
              <div>
                Calendar of when projects should happen around your house.
              </div>
            </Column>
            <Column>
              <ProductImage src="/avoid-expensive-suprises.png" />
            </Column>
          </SectionWrap>
        </Section>

        <Section className='green'>
          <SectionWrap>
            <Column>
              <ProductImage src="/no-more-forgetting.png" />
            </Column>
            <Column>
              <h3>No more forgetting</h3>
              <div>
                Store paint colors, receipts and more.
              </div>
            </Column>
          </SectionWrap>
        </Section>

        <Section>
          <SectionWrap>
            <Column>
              <h3>Meet your house</h3>
              <div>
                Find out how HouseIQ can support you.
              </div>
            </Column>
            <Column>
              <ProductImage src="/meet-your-house.png" />
            </Column>
          </SectionWrap>
        </Section>

        <Section className='faqs'>
          <SectionWrap>
            <h2>Frequently asked questions</h2>
            {faqs.map(faq => {
              return <Accordion faq={faq} />
            })}
          </SectionWrap>
        </Section>
      </Content>

      <Footer>
          <span>&copy; {new Date().getFullYear()} HouseIQ</span>
      </Footer>
    </>
  )
}

export default Landing
