import { ThingDefinitionAttributeDefinitionCheck } from '../db/types'

const millisecondsInDay = 86400000

export function filterChecks (checks: ThingDefinitionAttributeDefinitionCheck[]): ThingDefinitionAttributeDefinitionCheck[] {
  // Filter checks that aren't valid
  let filteredChecks = checks.filter(c => {
    // Check that a date isn't more then n days in the past
    if (c.attribute.attributeDefinition.type.value === 'date' && c.type.value === 'diff') {
      const currentDate = new Date()
      const attributeDate = new Date(c.attribute.value)

      const daysDiff = (currentDate.getTime() - attributeDate.getTime()) / millisecondsInDay

      const checkDays = parseInt(c.value, 10)
      if (checkDays === undefined) {
        return false
      }

      return (checkDays < daysDiff)
    }

    if (c.attribute.attributeDefinition.type.value === 'text' && c.type.value === 'equal') {
      return (c.value === c.attribute.value)
    }

    // Don't handle a check yet, disable it
    return false
  })

  filteredChecks = filteredChecks.filter((c, index, self) => {
    for (let otherIndex = 0; otherIndex < self.length; otherIndex++) {
      if (otherIndex === index) {
        continue
      }

      if (c.thing.id === self[otherIndex].thing.id && c.attribute.id === self[otherIndex].attribute.id) {
        if (c.severity === 'info' && (self[otherIndex].severity === 'warn' || self[otherIndex].severity === 'alert')) {
          return false
        } else if (c.severity === 'warn' && self[otherIndex].severity === 'alert') {
          return false
        }
      }
    }

    return true
  })

  return filteredChecks
}
