import React from 'react'
import Fuse from 'fuse.js'
import _ from 'lodash'
import DB from '../../db'
import { Project, ProjectSession } from '../../db/types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconForThingDefinition from '../../styles/icon'
import { useNavigate } from 'react-router-dom'

interface AllProps {
  db: DB
}

const keyEventUp = 'ArrowUp'
const keyEventDown = 'ArrowDown'

const ProjectStart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.grid.grid_32}px;
  min-height: 250px;
  background: ${props => props.theme.colors.black3};
  border-radius: ${props => props.theme.borderRadius.large}px;
`

const ProjectFormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 520px;
`

const ProjectInput = styled.input`
  padding: ${props => props.theme.grid.grid_12}px ${props => props.theme.grid.grid_16}px;
  width: 100%;
  border-radius: 100px;

  &:focus {
  }
`

const ProjectResults = styled.ul`
  position: absolute;
  top: 100%;
  list-style: none;
  text-align: left;
  padding: ${props => props.theme.grid.grid_8}px;
  margin-top: 4px;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.borderColor.soft};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};

  &:empty {
    visibility: hidden;
    opacity: 0;
  }

  li {
    padding: ${props => props.theme.grid.grid_12}px;
    border-radius: ${props => props.theme.borderRadius.default / 2}px;
    cursor: pointer;

    &.active {
      background: rgba(0,0,0,0.05);
    }

    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }
`

const ProjectSessionList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: ${props => props.theme.headerHeight * 2.3}px 0;
`

const ProjectSessionListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ProjectSessionListBody = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.grid.grid_16}px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

const ProjectSessionListItem = styled.div`
  padding: ${props => props.theme.grid.grid_24}px;
  background: ${props => props.theme.colors.shadow};
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};

  &:hover {
    border-color: ${props => props.theme.borderColor.hard};
    box-shadow: ${props => props.theme.shadows.small};
  }

  h5 {
    padding: 0;
  }
`

const ProjectSessionListItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.grid.grid_16}px;
`

const NoProjectSessionsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
`

const NoProjectSessionsMessageInner = styled.div`
  text-align: center;
  padding: ${props => props.theme.grid.grid_16}px;
`

const searchResultsLimit = 10

function All ({ db }: AllProps): JSX.Element | null {
  const [projectSessions, setProjectSessions] = React.useState<ProjectSession[]>([])
  const [currentProjectSearch, setCurrentProjectSearch] = React.useState('')
  const [projects, setProjects] = React.useState<Project[]>([])
  const [activeProject, setActiveProject] = React.useState('')
  const projectRef = React.useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  React.useEffect(() => {
    db.getProjectSessions().then(projectSessions => {
      setProjectSessions(projectSessions)
    }).catch(window.alert)
  }, [db])

  async function handleChange (event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await populateProjects()
  }

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === keyEventUp || event.key === keyEventDown) {
      let currentIndex = projects.findIndex(p => p.value === activeProject)
      if (event.key === keyEventDown) {
        currentIndex++
      } else if (event.key === keyEventUp) {
        currentIndex--
      }

      if (currentIndex <= -1) {
        currentIndex = projects.length - 1
      } else if (currentIndex >= projects.length) {
        currentIndex = 0
      }

      setActiveProject(projects[currentIndex].value)
    }

    if (event.key === 'Enter') {
      const currentIndex = projects.findIndex(p => p.value === activeProject)
      if (currentIndex !== -1) {
        navigate(`/create-project/${projects[currentIndex].id}`)
      }
    }
  }

  const populateProjects = async (): Promise<void> => {
    if (currentProjectSearch.length === 0) {
      db.getProjects().then(projects => {
        if (projects.length >= searchResultsLimit) {
          projects.length = searchResultsLimit
        }
        setProjects(projects)
      }).catch(window.alert)
      return
    }

    const projects = await db.getProjects()
    const projectAlternateValues = await db.getProjectAlternateValues()

    let searchResults = (new Fuse(projects, { keys: ['value'] })).search(currentProjectSearch)
    const alternateResults = (new Fuse(projectAlternateValues, { keys: ['value'] })).search(currentProjectSearch)

    for (const a of alternateResults) {
      const project = projects.find(p => p.id === a.item.project)
      if (project !== undefined) {
        searchResults.push({ item: project, refIndex: 0 })
      }
    }

    searchResults = searchResults.filter((value, index, self) => {
      return self.findIndex(v => v.item.id === value.item.id) === index
    })

    if (searchResults.length >= searchResultsLimit) {
      searchResults.length = searchResultsLimit
    }

    setProjects(searchResults.map(i => i.item))
  }

  return (
    <ProjectSessionList>

      <ProjectStart>
        <h2 className='page-title'>Start a project</h2>
        <ProjectFormContainer ref={projectRef}>
          <ProjectInput
            placeholder='Start a project...'
            onFocus={() => { populateProjects().catch(window.alert) }}
            onKeyDown={onKeyDown}
            value={currentProjectSearch}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentProjectSearch(event.target.value)
              handleChange(event).catch(window.alert)
            }}
          />

          <ProjectResults>
            {projects.map((project, i) => {
              return (
                <li
                  key={i}
                  className={activeProject === project.value ? 'active' : ''}
                  onClick={() => {
                    setProjects([])
                    navigate(`/create-project/${project.id}`)
                  }}
                >
                  {project.value}
                </li>
              )
            })}
          </ProjectResults>
        </ProjectFormContainer>
      </ProjectStart>

      <ProjectSessionListHeader className='page-header'>
        <h3 className='page-title'>My Projects</h3>
      </ProjectSessionListHeader>

      <h4>Open</h4>
      <ProjectSessionListBody>
        {projectSessions.map(p => {
          let thingDefinitionValue = 'custom'
          if (!_.isNil(p.thing.thingDefinition)) {
            thingDefinitionValue = p.thing.thingDefinition.value
          }

          return (
            <ProjectSessionListItem
              key={`${p.id}`}
              onClick={() => { navigate(`/project/${p.id}`) }}
            >
              <ProjectSessionListItemHeader>
                <FontAwesomeIcon icon={iconForThingDefinition(thingDefinitionValue)} />
              </ProjectSessionListItemHeader>

              <h5>{p.project.value}</h5>
            </ProjectSessionListItem>
          )
        })}
      </ProjectSessionListBody>

      {projectSessions.length === 0 &&
        <NoProjectSessionsMessage>
          <NoProjectSessionsMessageInner>
            <p>It looks empty here. Let's add something.</p>
          </NoProjectSessionsMessageInner>
        </NoProjectSessionsMessage>
      }

    </ProjectSessionList>
  )
}

export default All
