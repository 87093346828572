import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/Font.css'
import App from './App'
import GlobalStyles from './styles/globalStyles'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={(process as any).env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
)
