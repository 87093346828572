import {
  faBed,
  faHouse,
  faQuestion,
  faRug,
  faSink,
  faCouch,
  faDoorClosed,
  faLightbulb,
  faTv,
  faFire,
  faSquare,
  faFireBurner,
  faHotTubPerson,
  faSnowflake,
  faToilet,
  faSoap,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'

export default function iconForThingDefinition (thingDefinition: string): IconDefinition {
  switch (thingDefinition) {
    case 'Bed':
      return faBed
    case 'Carpet':
      return faRug
    case 'Couch':
      return faCouch
    case 'Door':
      return faDoorClosed
    case 'Fireplace':
    case 'Furnace':
    case 'Smoke Detector':
      return faFire
    case 'Clothes Washer':
    case 'Dishwasher':
      return faSoap
    case 'Lamp':
    case 'Light':
      return faLightbulb
    case 'Stove':
      return faFireBurner
    case 'Refrigerator':
    case 'Central Air Conditioner':
      return faSnowflake
    case 'Sink':
      return faSink
    case 'Toilet':
      return faToilet
    case 'Television':
      return faTv
    case 'Water Heater':
      return faHotTubPerson
    case 'Window':
      return faSquare
    case 'Custom':
      return faQuestion
    default:
      return faHouse
  }
}
