import React from 'react'
import DB from '../../db'
import styled from 'styled-components'
import Button from '../Button'
import LeftChevronIcon from '../Icons/LeftChevron'
import RightChevronIcon from '../Icons/RightChevron'

import { ThingDefinitionAttributeDefinitionCheck } from '../../db/types'

interface ProjectProps {
  db: DB
}

const ScheduleContainer = styled.div`
  padding:
  ${props => props.theme.headerHeight * 2.5}px
  ${props => props.theme.grid.grid_32}px;
`

const ScheduleHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const CalendarWrap = styled.div`
`

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.grid.grid_16}px;
`

const ScheduleBody = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    flex-direction: column;
  }
`

const Calendar = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 0 calc(14% - 10px);
    margin: 5px;
    max-width: calc(14% - 10px);
    padding: ${props => props.theme.grid.grid_8}px;
  }

  li, ul {
    padding: 0;
    list-style: none;
    overflow: hidden;
    white-space: nowrap;
  }
`

const CalendarWeekdayHeader = styled.div`
  color: ${props => props.theme.colors.black50};
  font-size: ${props => props.theme.text.size.small}px;
  text-align: center;
`

const CalendarDay = styled.div`
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  height: 100px;

  &.is-today {
    color: ${props => props.theme.colors.green};
    font-weight: 600;
    border-color: ${props => props.theme.colors.green};
    box-shadow: ${props => props.theme.shadows.large};
  }
`

const CalendarNav = styled.div`
  bottom: ${props => props.theme.grid.grid_24}px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  max-width: ${props => props.theme.breakpoints.small}px;

  button {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
    }
  }
`

const ScheduleViewToggle = styled.div`

`

const ScheduleViewToggleItem = styled.div`

`

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

function daysInMonth (year: number, month: number): number {
  return new Date(Date.UTC(year, month + 1, 0)).getDate() + 1
}

function checksForDate (checks: ThingDefinitionAttributeDefinitionCheck[], selectedDate: Date): Map<number, ThingDefinitionAttributeDefinitionCheck[]> {
  const newEntries = new Map<number, ThingDefinitionAttributeDefinitionCheck[]>()

  checks.forEach(check => {
    if (check.attribute.attributeDefinition.type.value !== 'date') {
      return
    }

    const checkDate = new Date(`${check.attribute.value}:`)
    if (!(checkDate instanceof Date)) {
      return
    }

    const dateIncreaseSize = parseInt(check.value, 10)
    if (isNaN(dateIncreaseSize)) {
      return
    }

    for (let i = 0; i <= 500; i++) {
      checkDate.setDate(checkDate.getDate() + dateIncreaseSize)
      const checkMonth = checkDate.getMonth()
      const checkYear = checkDate.getFullYear()
      const currentMonth = selectedDate.getMonth()
      const currentYear = selectedDate.getFullYear()

      if (checkYear > currentYear && checkMonth > currentMonth) {
        return
      }

      if (checkYear === currentYear && checkMonth === currentMonth) {
        const entriesForDate = newEntries.get(checkDate.getDate()) ?? []
        entriesForDate.push(check)
        newEntries.set(checkDate.getDate(), entriesForDate)
      }
    }
  })

  return newEntries
}

function checksSummary (checks?: ThingDefinitionAttributeDefinitionCheck[]): string[] {
  if (checks === undefined) {
    return []
  }

  const checksSummary: string[] = []

  checks.forEach(check => {
    checksSummary.push(`${check.thing.value} - ${check.attribute.attributeDefinition.value}`)
  })

  return checksSummary
}

function getLocalMonth (): Date {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
}

function getSevenDays (inDate: Date): string[] {
  const out: string[] = []

  for (let i = 0; i < 7; i++) {
    out.push(inDate.toLocaleDateString(undefined, { weekday: 'short' }))
    inDate.setDate(inDate.getDate() + 1)
  }

  return out
}

function Schedule ({ db }: ProjectProps): JSX.Element | null {
  const [selectedDate, setSelectedDate] = React.useState<Date>(getLocalMonth())
  const [checks, setChecks] = React.useState<ThingDefinitionAttributeDefinitionCheck[]>([])
  const [calendarEntries, setCalendarEntries] = React.useState<Map<number, ThingDefinitionAttributeDefinitionCheck[]>>(
    new Map<number, ThingDefinitionAttributeDefinitionCheck[]>()
  )
  const now = new Date()

  React.useEffect(() => {
    db.getThingDefinitionAttributeDefinitionChecks().then(checks => {
      setChecks(checks)
    }).catch(window.alert)
  }, [db])

  React.useEffect(() => {
    setCalendarEntries(checksForDate(checks, selectedDate))

    let currentDate = selectedDate
    const newUpcoming: ThingDefinitionAttributeDefinitionCheck[] = []
    for (let i = 0; i <= 12; i++) {
      checksForDate(checks, currentDate).forEach((value: ThingDefinitionAttributeDefinitionCheck[]) => {
        newUpcoming.push(...value)
      })

      if (currentDate.getMonth() === 11) {
        currentDate = new Date(currentDate.getFullYear() + 1, 0, 1)
      } else {
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
      }
    }
  }, [selectedDate, checks])

  return (
    <ScheduleContainer>
      <ScheduleHeader className='page-header'>
        <h3 className='page-title'>Schedule</h3>
        <ScheduleViewToggle>
          <ScheduleViewToggleItem></ScheduleViewToggleItem>
          <ScheduleViewToggleItem></ScheduleViewToggleItem>
        </ScheduleViewToggle>
      </ScheduleHeader>

      <ScheduleBody>
        <CalendarWrap>
          <CalendarHeader>
            <h4>{monthNames[selectedDate.getMonth()]} {selectedDate.getFullYear()}</h4>

            <CalendarNav>
              <Button
                onClick={() => {
                  if (selectedDate.getMonth() === 0) {
                    setSelectedDate(new Date(selectedDate.getFullYear() - 1, 11, 1))
                  } else {
                    setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1))
                  }
                }}
                className={'utility'}
              >
                <LeftChevronIcon />
              </Button>

              <Button
                onClick={() => {
                  setSelectedDate(getLocalMonth())
                }}
                text='Today'
                className={'utility'}
              />

              <Button
                onClick={() => {
                  if (selectedDate.getMonth() === 11) {
                    setSelectedDate(new Date(selectedDate.getFullYear() + 1, 0, 1))
                  } else {
                    setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1))
                  }
                }}
                className={'utility'}
              >
                <RightChevronIcon />
              </Button>
            </CalendarNav>
          </CalendarHeader>

          <Calendar>

            {getSevenDays(new Date(selectedDate)).map((day, i) => {
              return <CalendarWeekdayHeader key={i}> {day} </CalendarWeekdayHeader>
            })}

            {[...Array(daysInMonth(selectedDate.getFullYear(), selectedDate.getMonth()))].map((x, i) => {
              const dayOfMonth = i + 1

              const isToday = selectedDate.getFullYear() === now.getFullYear() &&
                              selectedDate.getMonth() === now.getMonth() &&
                              dayOfMonth === now.getDate()

              return (
              <CalendarDay
                key={dayOfMonth}
                className={`${isToday ? 'is-today' : ''}`}
              >
                {dayOfMonth}
                <ul>
                  {checksSummary(calendarEntries.get(dayOfMonth)).map((c, i) => {
                    return (
                      <li key={i}>
                        {c}
                      </li>
                    )
                  })}
                </ul>
              </CalendarDay>
              )
            })}
          </Calendar>
        </CalendarWrap>
      </ScheduleBody>

    </ScheduleContainer>
  )
}

export default Schedule
