import React from 'react'
import Fuse from 'fuse.js'
import _ from 'lodash'
import styled from 'styled-components'
import DB from '../../db'
import { Project, ThingDefinitionAttributeDefinitionCheck } from '../../db/types'
import { filterChecks } from '../../AttributeCheck'
import UserAccountMenu from '../UserAccountMenu'
import BellIcon from '../Icons/Bell'
import detectOutsideClick from '../DetectOutsideClick'

import { useNavigate } from 'react-router-dom'

interface TopbarProps {
  db: DB
}

const keyEventUp = 'ArrowUp'
const keyEventDown = 'ArrowDown'

const Header = styled.header`
  position: fixed;
  top: 0;
  left: ${props => props.theme.header.width}px;
  right: 0;
  padding: 0 ${props => props.theme.grid.grid_64}px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.theme.header.height}px;
  background: rgba(255,255,255,0.85);
  backdrop-filter: blur(10px);
  z-index: 9000;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    left: 0;
    padding: 0 ${props => props.theme.grid.grid_32}px;
  }
`

const Nav = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.mediaQueries.desktop}px;

  @media (max-width: ${props => props.theme.mediaQueries.large}px) {
    justify-content: space-between;
  }
`

const Utilities = styled.div`
  display: flex;
  position: absolute;
  right: 0;

  @media (max-width: ${props => props.theme.mediaQueries.large}px) {
    position: relative;
    padding-left: ${props => props.theme.grid.grid_16}px;
  }
`

const Inbox = styled.span`
  display: flex;
`

const InboxTrigger = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: ${props => props.theme.grid.grid_8}px;
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;

  svg {
    height: 20px;
    margin-right: ${props => props.theme.grid.grid_8 / 2}px;
  }

  &:hover {
    background: rgba(0,0,0,0.08);
  }
`

const InboxDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 360px;
  margin-top: ${props => props.theme.grid.grid_8}px;
  padding: ${props => props.theme.grid.grid_8}px;
  border: solid 1px ${props => props.theme.borderColor.soft};
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};
  transition: ${props => props.theme.transitions.quick};

  &.hidden {
    top: calc(100% - ${props => props.theme.grid.grid_8}px);
  }
`

const InboxDropdownItem = styled.div`
  padding: ${props => props.theme.grid.grid_16}px;
  cursor: pointer;
`

const NoInboxDropdownItem = styled.div`
  font-size: ${props => props.theme.text.size.small}px;
  text-align: center;
  padding: ${props => props.theme.grid.grid_16}px;
  width: 100%;
`

const InboxNotificationDot = styled.span`
  position: absolute;
  top: ${props => props.theme.grid.grid_8 / 2}px;
  right: ${props => props.theme.grid.grid_8 / 2}px;
  height: ${props => props.theme.grid.grid_8}px;
  width: ${props => props.theme.grid.grid_8}px;
  background: ${props => props.theme.colors.red};
  border-radius: ${props => props.theme.grid.grid_8}px;
`

const ProjectFormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 520px;
`

const ProjectInput = styled.input`
  padding: ${props => props.theme.grid.grid_12}px ${props => props.theme.grid.grid_16}px;
  width: 100%;
  border: 0;
  border-radius: 100px;

  &:focus {
  }
`

const ProjectResults = styled.ul`
  position: absolute;
  top: 100%;
  list-style: none;
  text-align: left;
  padding: ${props => props.theme.grid.grid_8}px;
  margin-top: 4px;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.borderColor.soft};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};

  &:empty {
    visibility: hidden;
    opacity: 0;
  }

  li {
    padding: ${props => props.theme.grid.grid_12}px;
    border-radius: ${props => props.theme.borderRadius.default / 2}px;
    cursor: pointer;

    &.active {
      background: rgba(0,0,0,0.05);
    }

    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }
`

const searchResultsLimit = 10

function Topbar ({ db }: TopbarProps): JSX.Element | null {
  const [checks, setChecks] = React.useState<ThingDefinitionAttributeDefinitionCheck[]>([])
  const [inboxHidden, setInboxHidden] = React.useState(true)
  const navigate = useNavigate()
  const [projects, setProjects] = React.useState<Project[]>([])
  const [activeProject, setActiveProject] = React.useState('')
  const [currentProjectSearch, setCurrentProjectSearch] = React.useState('')
  const inboxRef = React.useRef<HTMLSpanElement>(null)
  const projectRef = React.useRef<HTMLDivElement>(null)

  detectOutsideClick(inboxRef, () => {
    setInboxHidden(true)
  })

  detectOutsideClick(projectRef, () => {
    setProjects([])
  })

  const populateProjects = async (): Promise<void> => {
    if (currentProjectSearch.length === 0) {
      db.getProjects().then(projects => {
        if (projects.length >= searchResultsLimit) {
          projects.length = searchResultsLimit
        }
        setProjects(projects)
      }).catch(window.alert)
      return
    }

    const projects = await db.getProjects()
    const projectAlternateValues = await db.getProjectAlternateValues()

    let searchResults = (new Fuse(projects, { keys: ['value'] })).search(currentProjectSearch)
    const alternateResults = (new Fuse(projectAlternateValues, { keys: ['value'] })).search(currentProjectSearch)

    for (const a of alternateResults) {
      const project = projects.find(p => p.id === a.item.project)
      if (project !== undefined) {
        searchResults.push({ item: project, refIndex: 0 })
      }
    }

    searchResults = searchResults.filter((value, index, self) => {
      return self.findIndex(v => v.item.id === value.item.id) === index
    })

    if (searchResults.length >= searchResultsLimit) {
      searchResults.length = searchResultsLimit
    }

    setProjects(searchResults.map(i => i.item))
  }

  async function handleChange (event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await populateProjects()
  }

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === keyEventUp || event.key === keyEventDown) {
      let currentIndex = projects.findIndex(p => p.value === activeProject)
      if (event.key === keyEventDown) {
        currentIndex++
      } else if (event.key === keyEventUp) {
        currentIndex--
      }

      if (currentIndex <= -1) {
        currentIndex = projects.length - 1
      } else if (currentIndex >= projects.length) {
        currentIndex = 0
      }

      setActiveProject(projects[currentIndex].value)
    }

    if (event.key === 'Enter') {
      const currentIndex = projects.findIndex(p => p.value === activeProject)
      if (currentIndex !== -1) {
        navigate(`/create-project/${projects[currentIndex].id}`)
      }
    }
  }

  db.getThingDefinitionAttributeDefinitionChecks().then(c => {
    const newChecks = filterChecks(c)
    if (!_.isEqual(checks, newChecks)) {
      setChecks(newChecks)
    }
  }).catch(window.alert)

  return (
    <Header>
      <Nav>
        <ProjectFormContainer ref={projectRef}>
          <ProjectInput
            placeholder='Start a project...'
            onFocus={() => { populateProjects().catch(window.alert) }}
            onKeyDown={onKeyDown}
            value={currentProjectSearch}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentProjectSearch(event.target.value)
              handleChange(event).catch(window.alert)
            }}
          />

          <ProjectResults>
            {projects.map((project, i) => {
              return (
                <li
                  key={i}
                  className={activeProject === project.value ? 'active' : ''}
                  onClick={() => {
                    setProjects([])
                    navigate(`/create-project/${project.id}`)
                  }}
                >
                  {project.value}
                </li>
              )
            })}
          </ProjectResults>
        </ProjectFormContainer>

        <Utilities>
          <Inbox ref={inboxRef} onClick={ () => { setInboxHidden(inboxHidden => !inboxHidden) }}>
            <InboxTrigger>
              <BellIcon />
              {checks.length !== 0 &&
                <InboxNotificationDot />
              }
            </InboxTrigger>

            <InboxDropdown className={`${inboxHidden ? 'hidden' : ''}`} onClick={e => { e.stopPropagation() }}>
              {checks.length === 0 &&
                <NoInboxDropdownItem>You have no new updates.</NoInboxDropdownItem>
              }
              {checks.map((check) => {
                return <InboxDropdownItem key={check.id}>
                  {check.message} - {check.severity}
                </InboxDropdownItem>
              })}
            </InboxDropdown>
          </Inbox>
          <UserAccountMenu />
        </Utilities>
      </Nav>
    </Header>
  )
}

export default Topbar
