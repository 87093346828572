import React from 'react'
import Fuse from 'fuse.js'
import styled from 'styled-components'
import detectOutsideClick from '../DetectOutsideClick'

const keyEventUp = 'ArrowUp'
const keyEventDown = 'ArrowDown'

const OptionsList = styled.ul`
  position: absolute;
  list-style: none;
  text-align: left;
  margin: ${props => props.theme.grid.grid_8 / 2}px 0 0;
  padding: ${props => props.theme.grid.grid_8}px;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};
  z-index: 9999;

  &:empty {
    visibility: hidden;
    opacity: 0;
  }

  li {
    padding: ${props => props.theme.grid.grid_12}px;
    border-radius: ${props => props.theme.borderRadius.default / 2}px;
    cursor: pointer;

    &:hover {
      background: rgba(0,0,0,0.05);
    }

    &.active {
      background: rgba(0,0,0,0.05);
    }
  }
`

interface SearchInputProps {
  label: string
  itemList: string[]
  placeHolder: string
  inputValue: string
  setInputValue: (inputValue: string) => void
}

function SearchInput ({ label, itemList, placeHolder, inputValue, setInputValue }: SearchInputProps): JSX.Element | null {
  const [selectionList, setSelectionList] = React.useState<string[]>([])
  const [activeItem, setActiveItem] = React.useState('')
  const ref = React.useRef<HTMLUListElement>(null)

  detectOutsideClick(ref, () => {
    setSelectionList([])
  })

  const populateSelectionList = (search: string): void => {
    if (search === '') {
      setSelectionList(itemList)
    } else {
      const fuseSearch = new Fuse(itemList)
      setSelectionList(fuseSearch.search(search).map(i => i.item))
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value)
    populateSelectionList(e.target.value)
  }

  const selectSearchResult = (searchResult: string): void => {
    setSelectionList([])
    setInputValue(searchResult)
  }

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === keyEventUp || event.key === keyEventDown) {
      let currentIndex = itemList.findIndex(i => i === activeItem)
      if (event.key === keyEventDown) {
        currentIndex++
      } else if (event.key === keyEventUp) {
        currentIndex--
      }

      if (currentIndex <= -1) {
        currentIndex = itemList.length - 1
      } else if (currentIndex >= itemList.length) {
        currentIndex = 0
      }

      setActiveItem(itemList[currentIndex])
    }

    if (event.key === 'Enter') {
      const currentIndex = itemList.findIndex(i => i === activeItem)
      if (currentIndex !== -1) {
        selectSearchResult(itemList[currentIndex])
      }
    }
  }

  return (
    <>
      <label>{label}</label>
      <input
        type='text'
        placeholder={placeHolder}
        onChange={onInputChange}
        onFocus={() => {
          populateSelectionList(inputValue)
        }}
        onKeyDown={onKeyDown}
        value={inputValue}
      />
      <OptionsList ref={ref}>
        {selectionList.map((selection, i) => {
          return (
            <li key={i}
              className={activeItem === selection ? 'active' : ''}
              onClick={() => { selectSearchResult(selection) }}
            >
              {selection}
            </li>)
        })}
      </OptionsList>
    </>
  )
}

export default SearchInput
