import React from 'react'
import ResetStyles from './globalStyles/reset'
import BaseStyles from './globalStyles/base'

import ItemStyles from './globalStyles/items'
import FormStyles from './globalStyles/forms'
import TypeStyles from './globalStyles/type'

function GlobalStyles (): JSX.Element | null {
  return (
    <div>
      <ResetStyles />
      <BaseStyles />
      <FormStyles />
      <ItemStyles />
      <TypeStyles />
    </div>
  )
}

export default GlobalStyles
