import React from 'react'
import _ from 'lodash'
import DB from '../../db'
import styled from 'styled-components'
import detectOutsideClick from '../DetectOutsideClick'
import ActionMenu from '../ActionMenu'
import PlusIcon from '../Icons/Plus'
import Button from '../Button'

import {
  Thing,
  AttributeDefinition,
  AttributeType,
  CustomAttribute
} from '../../db/types'

const ThingDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .rename {
    display: none !important;
  }
`

const CreateAttributeWrap = styled.div`
  position: relative;

  h5 {
    margin-bottom: ${props => props.theme.grid.grid_12}px;
  }
`

const CreateAttributeTrigger = styled.a`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.grid.grid_8}px;

  svg {
    fill: ${props => props.theme.colors.green};
    height: 14px;
    margin-right: ${props => props.theme.grid.grid_8}px;
  }
`

const CreateAttributePopover = styled.div`
  display: none;
  padding: ${props => props.theme.grid.grid_12}px 0;

  .header {
    display: flex;
    justify-content: space-between;
  }

  &.active {
    display: block;
  }
`

const ThingDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding:
    ${props => props.theme.grid.grid_64 * 1.5}px
    ${props => props.theme.grid.grid_32}px
    ${props => props.theme.grid.grid_32}px !important;
  background: ${props => props.theme.colors.tan};

  .thingName {
    margin-bottom: ${props => props.theme.grid.grid_8}px;
  }

  .roomName {
    color: ${props => props.theme.colors.black80};
  }

  @media (max-height: ${props => props.theme.mediaQueries.lowHeight}px) {
    padding-top: ${props => props.theme.grid.grid_32}px !important;
  }
`

const ThingDetailBody = styled.div`
`

const ThingDetailFooter = styled.div`
`

interface ThingDetailProps {
  db: DB
  thing: Thing
  setIsHidden: (isHidden: boolean) => void
}

function ThingDetail ({ db, thing, setIsHidden }: ThingDetailProps): JSX.Element | null {
  const [attributeDefinitions, setAttributeDefinitions] = React.useState<AttributeDefinition[]>([])
  const [attributes, setAttributes] = React.useState<Map<number, string>>(new Map())
  const [customAttributeValues, setCustomAttributeValues] = React.useState<Map<number, string>>(new Map())
  const [thingValue, setThingValue] = React.useState(thing.value)
  const [parentThing, setParentThing] = React.useState<Thing | undefined>(undefined)
  const [attributeTypes, setAttributeTypes] = React.useState<AttributeType[]>([])
  const [customAttributeValue, setCustomAttributeValue] = React.useState('')
  const [customAttributes, setCustomAttributes] = React.useState<CustomAttribute[]>([])
  const customAttributeSelect = React.useRef<HTMLSelectElement>(null)
  const [customAttributePopoverHidden, setCustomAttributePopoverHidden] = React.useState(true)
  const customAttributeRef = React.useRef<HTMLDivElement>(null)
  const bottomRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    populate()
  }, [db, thing])

  detectOutsideClick(customAttributeRef, () => {
    setCustomAttributePopoverHidden(true)
  })

  const bottomScroll = (): void => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const saveThing = (): void => {
    attributes.forEach((value: string, key: number) => {
      db.createAttribute(thing.id, key, value).catch(window.alert)
    })
    customAttributeValues.forEach((value: string, key: number) => {
      const customAttribute = customAttributes.find(c => c.id === key)
      if (customAttribute !== undefined) {
        db.createCustomAttribute(
          thing.id,
          customAttribute.type.id,
          customAttribute.label,
          value
        ).catch(window.alert)
      }
    })

    db.updateThing(thing.id, thingValue).catch(window.alert)
  }

  const populate = (): void => {
    if (db === undefined || thing === undefined) {
      return
    }

    setCustomAttributeValue('')

    db.getCustomAttributes(thing.id).then(customAttributes => {
      setCustomAttributes(customAttributes)

      const loadedCustomAttributes = new Map()
      customAttributes.forEach(customAttribute => {
        loadedCustomAttributes.set(customAttribute.id, customAttribute.value)
      })
      setCustomAttributeValues(loadedCustomAttributes)
    }).catch(window.alert)

    db.getThingRelationships(thing.id).then(relationships => {
      const childRelationship = relationships.find(r => r.type === 'child')
      if (childRelationship === undefined) {
        return
      }

      setParentThing(childRelationship.self)
    }).catch(window.alert)

    if (!_.isNil(thing.thingDefinition)) {
      db.getAttributeDefinitions(thing.thingDefinition.id).then(d => {
        setAttributeDefinitions(d)
      }).catch(window.alert)
    }

    db.getAttributeTypes().then(attributeTypes => {
      setAttributeTypes(attributeTypes)
    }).catch(window.alert)

    db.getAttributes(thing.id).then(attributes => {
      const loadedAttributes = new Map()
      attributes.forEach(attribute => {
        loadedAttributes.set(attribute.attributeDefinition.id, attribute.value)
      })
      setAttributes(loadedAttributes)
    }).catch(window.alert)
  }

  const createCustomAttribute = (): void => {
    if (customAttributeSelect.current == null) {
      return
    }

    const attributeType = Number(customAttributeSelect.current.value)
    if (isNaN(attributeType)) {
      return
    }

    db.createCustomAttribute(
      thing.id,
      attributeType,
      customAttributeValue,
      ''
    ).then(() => {
      populate()
    }).catch(window.alert)

    setCustomAttributePopoverHidden(true)
  }

  const attributeRow = (
    i: number,
    key: string,
    type: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  ): JSX.Element => {
    let input = <input type={type} value={value} onChange={onChange } />
    if (type === 'textarea') {
      input = <textarea value={value} onChange={onChange}> </textarea>
    } else if (type === 'date') {
      const onDateClick = (e: React.MouseEvent<HTMLInputElement>): void => {
        if ((e.target as any).showPicker !== null) {
          (e.target as any).showPicker()
        }
      }
      input = <input type='date' value={value} onChange={onChange} onClick={onDateClick} />
    }

    return (
      <div className='fieldGroup' key={i}>
        <label>{key}</label>
        {input}
      </div>
    )
  }

  return (
    <ThingDetailContainer>
      <ThingDetailHeader className='modalHeader'>
        <div>
          <h4 className='thingName'>{thingValue}</h4>
          {parentThing !== undefined && <span className='roomName'> {parentThing.value} </span>}
        </div>
        <ActionMenu
          onDelete={() => {
            db.deleteThing(thing.id).then(() => {
              setIsHidden(true)
            }).catch(window.alert)
          }}
          onRename={() => {}}
        />
      </ThingDetailHeader>

      <ThingDetailBody className='modalBody' ref={bottomRef}>

        <div className='fieldGroup'>
          <label>Name</label>
          <input type='text'
                value={thingValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setThingValue(e.target.value)
                  saveThing()
                }}
          />
        </div>

        <div className='fields'>
          {attributeDefinitions.map((attributeDefinition) => {
            return attributeRow(
              attributeDefinition.id,
              attributeDefinition.value,
              attributeDefinition.type.value,
              attributes.get(attributeDefinition.id) ?? '',
              (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                setAttributes(new Map(attributes.set(attributeDefinition.id, e.target.value)))
                saveThing()
              }
            )
          })}
        </div>

        <div className='fieldsCustom'>
          {customAttributes.map((customAttribute) => {
            return attributeRow(
              customAttribute.id,
              customAttribute.label,
              customAttribute.type.value,
              customAttributeValues.get(customAttribute.id) ?? '',
              (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                setCustomAttributeValues(new Map(customAttributeValues.set(customAttribute.id, e.target.value)))
                saveThing()
              }
            )
          })}
        </div>

        <CreateAttributeWrap ref={customAttributeRef} onClick={ () => { setCustomAttributePopoverHidden(customAttributePopoverHidden => !customAttributePopoverHidden) }}>
          <CreateAttributePopover className={`${customAttributePopoverHidden ? '' : 'active'}`} onClick={e => { e.stopPropagation() }}>
            <div className='header'>
              <h5>Add field</h5>
              <a onClick={ () => { setCustomAttributePopoverHidden(customAttributePopoverHidden => !customAttributePopoverHidden) }}>Cancel</a>
            </div>
            <div className='fieldGroup single'>
              <div className='field'>
                <label>Label</label>
                <input type='text'
                  value={customAttributeValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setCustomAttributeValue(e.target.value)
                  }}
                />
              </div>
              <div className='field short'>
                <label> Type </label>
                <select ref={customAttributeSelect}>
                  {attributeTypes.map(a => {
                    return (
                      <option value={a.id} key={a.id}> {a.value} </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <Button className={'full'} onClick={createCustomAttribute} text='Add field' />
          </CreateAttributePopover>
          <CreateAttributeTrigger onClick={bottomScroll}>
            <PlusIcon />
            <span>Add custom field</span>
          </CreateAttributeTrigger>
        </CreateAttributeWrap>

      </ThingDetailBody>
      <ThingDetailFooter className='modalFooter'>
        <Button className={'update full'} onClick={saveThing} text='Update' />
      </ThingDetailFooter>
    </ThingDetailContainer>
  )
}

export default ThingDetail
