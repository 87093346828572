import React from 'react'
import styled from 'styled-components'

interface FAQ {
  question: string
  answer: string
}

interface AccordionProps {
  faq: FAQ
}

const AccordionWrap = styled.div`
  list-style: none;
  width: 100%;
`

const AccordionItem = styled.div`
  margin: ${props => props.theme.grid.grid_8 / 1.5}px 0;
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  transition: ${props => props.theme.transitions.quick};
  background: ${props => props.theme.colors.white};

  &:hover,
  &.active {
    box-shadow: ${props => props.theme.shadows.medium};
  }
`

const AccordionQuestion = styled.div`
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.grid.grid_24}px;
  cursor: pointer;
`

const AccordionAnswer = styled.div`
  color: ${props => props.theme.colors.black80};
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;

  &.active {
    height: auto;
  }
`

const AnswerText = styled.div`
  padding: ${props => props.theme.grid.grid_8}px ${props => props.theme.grid.grid_24}px ${props => props.theme.grid.grid_24}px;
`

function Accordion ({ faq }: AccordionProps): JSX.Element | null {
  const [clicked, setAccordionItemHidden] = React.useState(false)
  const contentEl = React.useRef<HTMLDivElement>(null)
  const { question, answer } = faq

  function toggleAccordionItem (): void {
    setAccordionItemHidden(clicked => !clicked)
  }

  return (
    <AccordionWrap className='accordion'>
      <AccordionItem className={`${clicked ? 'active' : ''}`}>
        <AccordionQuestion className="button" onClick={toggleAccordionItem}>
            {question}
            <span className='control'>{clicked ? '—' : '+'} </span>
        </AccordionQuestion>

        <AccordionAnswer
            ref={contentEl}
            className={`${clicked ? 'active' : ''}`}
        >
            <AnswerText className='answer'>{answer}</AnswerText>
        </AccordionAnswer>
      </AccordionItem>
    </AccordionWrap>
  )
}

export default Accordion
