import { createGlobalStyle } from 'styled-components'

const TypeStyles = createGlobalStyle`
  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: 800;
  }

  h2 {
    font-size: 2rem;
    font-weight: 800;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1rem;
  }

  /* Paragraph */
  p {
    line-height: 150%;
    margin: 0;
    margin-bottom: 24px;
  }
`

export default TypeStyles
