import { createGlobalStyle } from 'styled-components'

const FormStyles = createGlobalStyle`
  ::placeholder {
    color: rgba(0,0,0,0.3);
  }

  label {
    display: block;
    font-size: ${props => props.theme.text.size.small}px;
    margin-bottom: ${props => props.theme.grid.grid_8}px;
  }

  input,
  select,
  textarea {
    padding: ${props => props.theme.grid.grid_8}px;
    width: 100%;
    background: ${props => props.theme.colors.white};
    border: solid 1px rgba(0,0,0,0.15);
    border-radius: ${props => props.theme.borderRadius.default}px;
    transition: ${props => props.theme.transitions.quick};

    &:focus {
      border-color: ${props => props.theme.colors.green};
    }
  }

  .fieldGroup {
    position: relative;
    margin-bottom: ${props => props.theme.grid.grid_16}px;

    .field {
      flex: 3;

      &:last-child {
        margin-left: ${props => props.theme.grid.grid_8}px;
      }

      &.short {
        flex: 1;
      }
    }

    &.single {
      display: flex;
    }
  }

  select {
    text-transform: capitalize;
  }
`

export default FormStyles
