import React from 'react'
import styled from 'styled-components'
import detectOutsideClick from '../DetectOutsideClick'
import SparkleIcon from '../Icons/Sparkle'
import Button from '../Button'

interface FeedbackProps {
  onClick?: () => void
}

const FeedbackWrap = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: 100%;
`

const FeedbackToggle = styled.a`
  display: flex;
  color: ${props => props.theme.colors.black};
  font-weight: 600;
  padding: ${props => props.theme.grid.grid_16}px ${props => props.theme.grid.grid_24}px;
  margin-bottom: ${props => props.theme.grid.grid_8 / 2}px;
  border-radius: 100px;
  text-decoration: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};

  &.active,
  &:hover {
    color: ${props => props.theme.colors.green_dark};
    background: ${props => props.theme.colors.black3};
  }

  svg {
    width: 14px;
  }

  span {
    font-size: ${props => props.theme.text.size.small}px;
    margin-right: ${props => props.theme.grid.grid_8}px;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    position: fixed;
    bottom: ${props => props.theme.grid.grid_8}px;
    right: ${props => props.theme.grid.grid_8}px;
    z-index: 9999;

    span {
      display: none;
    }
  }
`

const FeedbackPopover = styled.div`
  position: absolute;
  left ${props => props.theme.grid.grid_16}px;
  bottom: calc(100% + ${props => props.theme.grid.grid_16}px);
  padding: ${props => props.theme.grid.grid_32}px;
  width: 380px;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};
  transition: ${props => props.theme.transitions.quick};
  opacity: 1;
  visibility: visible;

  &.hidden {
    bottom: 100%;
  }

  h4 {
    margin-bottom: ${props => props.theme.grid.grid_8}px;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    bottom: ${props => props.theme.grid.grid_64}px;
    right: ${props => props.theme.grid.grid_16}px;
    width: auto;
    max-height: 70vh;
    overflow: auto;
  }
`

function Feedback ({ onClick }: FeedbackProps): JSX.Element | null {
  const [feedbackPopoverHidden, setFeedbackPopoverHidden] = React.useState(true)
  const feedbackRef = React.useRef<HTMLDivElement>(null)

  detectOutsideClick(feedbackRef, () => {
    setFeedbackPopoverHidden(true)
  })

  return (
    <FeedbackWrap ref={feedbackRef} onClick={ () => { setFeedbackPopoverHidden(feedbackHidden => !feedbackHidden) }}>
      <FeedbackToggle className={`${feedbackPopoverHidden ? '' : 'active'}`}>
        <span>Send feedback</span>
        <SparkleIcon />
      </FeedbackToggle>

      <FeedbackPopover className={`${feedbackPopoverHidden ? 'hidden' : ''}`} onClick={e => { e.stopPropagation() }}>
        <div className='fieldGroup'>
          <h4>Send feedback</h4>
          <p>How can we improve your experience?</p>
        </div>
        <div className='fieldGroup'>
          <label>Name</label>
          <input></input>
        </div>
        <div className='fieldGroup'>
          <label>Email</label>
          <input></input>
        </div>
        <div className='fieldGroup'>
          <label>Message</label>
          <textarea></textarea>
        </div>
        <div className='fieldGroup'>
          <input type="file" id="myFile" name="filename" />
        </div>
        <Button className='full' onClick={onClick} text='Send' />
      </FeedbackPopover>
    </FeedbackWrap>
  )
}

export default Feedback
