import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import ActionMenu from '../ActionMenu'
import Button from '../Button'
import Modal from '../Modal'
import { filterChecks } from '../../AttributeCheck'

import DB from '../../db'
import { Thing, ThingRelationship, ThingDefinitionAttributeDefinitionCheck } from '../../db/types'
import iconForThingDefinition from '../../styles/icon'

import CreateThingForm from '../CreateThingForm'
import ThingDetail from '../ThingDetail'

const MyHouseList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: ${props => props.theme.headerHeight * 2.3}px 0;
`

const MyHouseListHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.grid.grid_32}px;
  min-height: 250px;
  background: ${props => props.theme.colors.green_fade};
  border-radius: ${props => props.theme.borderRadius.large}px;
`

const MyHouseListHeaderActions = styled.div`
  position: absolute;
  top: ${props => props.theme.grid.grid_16}px;
  right: ${props => props.theme.grid.grid_16}px;
  display: flex;
`

const MyHouseRoomList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.grid.grid_32}px;
`

const MyHouseListBody = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.grid.grid_16}px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`

const MyHouseRoom = styled.div`
  padding: ${props => props.theme.grid.grid_32}px;
  margin-bottom: ${props => props.theme.grid.grid_32}px;
  border-radius: ${props => props.theme.borderRadius.large}px;

  &.last-child {
    margin-bottom: 0;
  }
`

const MyHouseRoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.grid.grid_16}px;
`

const MyHouseListItem = styled.div`
  position: relative;
  padding: ${props => props.theme.grid.grid_24}px;
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.small};
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};
  overflow: hidden;

  &:hover {


    .myHouseListItemImage {
      background-position: 16px 50%;
    }
  }

  h5 {
    padding: 0;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding-top: ${props => props.theme.grid.grid_24}px;
  }
`

const MyHouseListItemImage = styled.div`
  display: none; // temporary
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 90px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 32px 50%;
  background-color: ${props => props.theme.colors.white};
  transition: ${props => props.theme.transitions.quick};
`

const MyHouseListItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.grid.grid_16}px;
`

const CheckInfo = styled.div`
  display: flex !important;
  align-items: center;
  color: ${props => props.theme.colors.purple};
  font-size: ${props => props.theme.text.size.small}px;

  &::before {
    --size: 10px;
    content: '';
    display: block;
    height: var(--size);
    width: var(--size);
    background: ${props => props.theme.colors.purple};
    border-radius: var(--size);
  }
`
const CheckWarn = styled(CheckInfo)`
  color: ${props => props.theme.colors.orange};

  &::before {
    background: ${props => props.theme.colors.orange};
  }
`

const CheckAlert = styled(CheckInfo)`
  color: ${props => props.theme.colors.red};

  &::before {
    background: ${props => props.theme.colors.red};
  }
`

const NoHouseMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
`

const NoHouseMessageInner = styled.div`
  text-align: center;
  padding: ${props => props.theme.grid.grid_16}px;
`

interface MyHouseProps {
  db: DB
}

function MyHouse ({ db }: MyHouseProps): JSX.Element | null {
  const [thingRelationships, setThingRelationships] = React.useState<ThingRelationship[]>([])
  const [newThingModalIsHidden, setNewThingModalIsHidden] = React.useState(true)
  const [renameModalIsHidden, setRenameModalIsHidden] = React.useState(true)
  const [activeThing, setActiveThing] = React.useState<Thing | undefined>(undefined)
  const [activeRoom, setActiveRoom] = React.useState<Thing | undefined>(undefined)
  const [activeRoomValue, setActiveRoomValue] = React.useState('')
  const [checks, setChecks] = React.useState<ThingDefinitionAttributeDefinitionCheck[]>([])

  const populate = async (): Promise<void> => {
    const newThingRelationships = await db.getThingRelationships()
    if (!_.isEqual(newThingRelationships, thingRelationships)) {
      setThingRelationships(newThingRelationships)
    }

    let newChecks = await db.getThingDefinitionAttributeDefinitionChecks()
    newChecks = filterChecks(newChecks)
    if (!_.isEqual(checks, newChecks)) {
      setChecks(newChecks)
    }
  }

  function toggleRenameModal (): void {
    setRenameModalIsHidden(renameModalIsHidden => !renameModalIsHidden)
  }

  function toggleNewThingModal (): void {
    setNewThingModalIsHidden(newThingModalIsHidden => !newThingModalIsHidden)
  }

  function toggleThingDetailsModal (thing?: Thing): void {
    setActiveThing(thing)
  }

  React.useEffect(() => {
    populate().catch(window.alert)
  }, [activeThing, newThingModalIsHidden])

  populate().catch(window.alert)

  const renameRoom = (): void => {
    if (activeRoom !== undefined && activeRoomValue !== '') {
      db.updateThing(activeRoom.id, activeRoomValue)
        .then(() => {
          setActiveRoom(undefined)
          populate().catch(window.alert)
        }).catch(window.alert)
    }
  }

  return (
    <MyHouseList>

      <MyHouseListHeader>
        <h2 className='page-title'>My House</h2>
        <MyHouseListHeaderActions>
          <ActionMenu
                  onDelete={() => {
                  }}
                  onRename={() => {
                  }}
                />
        </MyHouseListHeaderActions>
      </MyHouseListHeader>

      <MyHouseRoomList>
        <h4>Rooms</h4>
        <Button onClick={toggleNewThingModal} text='Add New' className='' />
      </MyHouseRoomList>

      {thingRelationships
        .filter((c, index) => thingRelationships.findIndex(t => t.self.id === c.self.id) === index)
        .sort((a, b) => a.self.value.localeCompare(b.self.value))
        .filter((t) => !_.isNil(t.self.thingDefinition) && t.self.thingDefinition.value === 'Room')
        .map(room => {
          return (
          <MyHouseRoom key={room.id}>
            <MyHouseRoomHeader>
              <h5>{room.self.value}</h5>
              <ActionMenu
                onDelete={() => {
                  const deletions: Array<Promise<void>> = []

                  thingRelationships
                    .filter((t) => t.type === 'child')
                    .filter((t) => room.self.id === t.self.id)
                    .forEach(t => {
                      deletions.push(db.deleteThing(t.other.id))
                    })

                  Promise.all(deletions).then(() => {
                    populate().catch(window.alert)
                  }).catch(window.alert)
                }}
                onRename={() => {
                  toggleRenameModal()
                  setActiveRoomValue(room.self.value)
                  setActiveRoom(room.self)
                }}
              />
            </MyHouseRoomHeader>

            <MyHouseListBody>
              {thingRelationships
                .filter((t) => room.self.id === t.self.id)
                .sort((a, b) => a.other.value.localeCompare(b.other.value))
                .map((thing) => {
                  const thingChecks = checks.filter(check => check.thing.id === thing.other.id)
                  const hasInfo = thingChecks.find(check => check.severity === 'info') !== undefined
                  const hasWarn = thingChecks.find(check => check.severity === 'warn') !== undefined
                  const hasAlert = thingChecks.find(check => check.severity === 'alert') !== undefined

                  let thingDefinitionValue = 'Custom'
                  if (!_.isNil(thing.other.thingDefinition)) {
                    thingDefinitionValue = thing.other.thingDefinition.value
                  }

                  return (
                    <MyHouseListItem className={thingDefinitionValue.toLowerCase()} key={`${thing.other.id}`} onClick={() => { toggleThingDetailsModal(thing.other) }}>
                      <MyHouseListItemImage className='myHouseListItemImage' />
                      <MyHouseListItemHeader>
                        <FontAwesomeIcon icon={iconForThingDefinition(thingDefinitionValue)} />
                        {(() => {
                          if (hasAlert) {
                            return <CheckAlert></CheckAlert>
                          } else if (hasWarn) {
                            return <CheckWarn></CheckWarn>
                          } else if (hasInfo) {
                            return <CheckInfo></CheckInfo>
                          } else {
                            return <> </>
                          }
                        })()}
                      </MyHouseListItemHeader>

                      <h5>{thing.other.value}</h5>
                    </MyHouseListItem>
                  )
                })}
            </MyHouseListBody>
          </MyHouseRoom>
          )
        })
      }

      {thingRelationships.length === 0 &&
        <NoHouseMessage>
          <NoHouseMessageInner>
            <p>It looks empty here. Let's add something.</p>
          </NoHouseMessageInner>
        </NoHouseMessage>
      }

      <Modal onClick={toggleNewThingModal} isHidden={newThingModalIsHidden} className='createThing'>
        <CreateThingForm
          db={db}
          onFormSubmit={toggleNewThingModal}
          isHidden={newThingModalIsHidden}
        />
      </Modal>

      <Modal onClick={() => { toggleThingDetailsModal() }}
        isHidden={activeThing === undefined}
        className=''
      >
        {activeThing !== undefined && <ThingDetail db={db} thing={activeThing} setIsHidden={() => { setActiveThing(undefined) }} />}
      </Modal>

      <Modal
        onClick={toggleRenameModal}
        isHidden={renameModalIsHidden}
        className=''
      >
        {activeRoom !== undefined && <>
          <div className='modalHeader'>
            <h4>Rename room</h4>
          </div>
          <div className='modalBody'>
            <label> Room Name </label>
            <input
              value={activeRoomValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActiveRoomValue(e.target.value)
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  renameRoom()
                  toggleRenameModal()
                }
              }}
            />
          </div>
          <div className='modalFooter'>
          <Button
            onClick={() => {
              renameRoom()
              toggleRenameModal()
            }}
            text='Rename' />
          </div>
        </>
        }
      </Modal>

    </MyHouseList>
  )
}

export default MyHouse
