import React from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../Logo/Logo'
import HouseIcon from '../Icons/House'
import ProjectsIcon from '../Icons/Projects'
import CalendarIcon from '../Icons/Calendar'
import Feedback from '../Feedback'

import DB from '../../db'

interface SidebarProps {
  db: DB
}

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.grid.grid_32}px;
  height: ${props => props.theme.header.height}px;

  svg {
    height: 24px;
    width: auto;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    display: none;
  }
`

const SidebarNav = styled.div`
  flex: 2;
`

const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: ${props => props.theme.header.width}px;
  background: ${props => props.theme.colors.white};
  z-index: 2000;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    height: auto;
    width: 100%;
    border-right: 0;
    border-top: solid 1px ${props => props.theme.colors.black10};
  }
`

const SidebarLinkWrap = styled.div`
  padding: 0 ${props => props.theme.grid.grid_8}px;
  width: 100%;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    display: flex;
    justify-content: center;
    padding: ${props => props.theme.grid.grid_8}px;
  }
`

export const SidebarLink = styled.div`
  display: flex;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  padding: ${props => props.theme.grid.grid_16}px ${props => props.theme.grid.grid_24}px;
  border-radius: 100px;
  text-decoration: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};

  svg {
    width: 16px;
    margin-right: 12px;
  }

  &:hover {
    background: ${props => props.theme.colors.black3};
  }

  &.active {
    color: ${props => props.theme.colors.green_dark};

    svg {
      path {
        fill: ${props => props.theme.colors.green_dark};
      }
    }
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    flex: 0 0 auto;
    justify-content: center;
    margin: 0 ${props => props.theme.grid.grid_8 / 2}px;

    svg {
      width: 20px;
      margin: 0;
    }

    span {
      display: none;
    }
  }
`

const FeedbackWrap = styled.div`
  display: flex;
  padding: ${props => props.theme.grid.grid_8}px;
  width: 100%;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding: 0;
  }
`

function Sidebar ({ db }: SidebarProps): JSX.Element | null {
  const location = useLocation()
  const navigate = useNavigate()
  const isProject = location.pathname.includes('project')
  const isSchedule = location.pathname.includes('schedule')

  return (
    <SidebarWrap>
      <SidebarNav>
        <LogoWrap>
          <Logo/>
        </LogoWrap>

        <SidebarLinkWrap>
          <SidebarLink
            className={`${!isProject && !isSchedule ? 'active' : ''}`}
            onClick={() => { navigate('/') }}
          >
            <HouseIcon />
            <span>My House</span>
          </SidebarLink>

          <SidebarLink
            className={`${isProject ? 'active' : ''}`}
            onClick={() => { navigate('/project') }}
          >
            <ProjectsIcon />
            <span>Projects</span>
          </SidebarLink>

          <SidebarLink
            className={`${isSchedule ? 'active' : ''}`}
            onClick={() => { navigate('/schedule') }}
          >
            <CalendarIcon />
            <span>Schedule</span>
          </SidebarLink>
        </SidebarLinkWrap>
      </SidebarNav>

      <FeedbackWrap>
        <Feedback />
      </FeedbackWrap>
    </SidebarWrap>
  )
}

export default Sidebar
