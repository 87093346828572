import { createGlobalStyle } from 'styled-components'

const BaseStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    color: ${props => props.theme.colors.black80};
    font-family: "Plus Jakarta Sans", sans-serif;
    padding: 0;
    margin: 0;
  }

  body {
    font-size: ${props => props.theme.text.size.default}px;
  }

  a {
    color: ${props => props.theme.colors.green};
    cursor: pointer;
    transition: ${props => props.theme.transitions.quick};

    &:hover {
      color: ${props => props.theme.colors.green_dark};
    }
  }

  ::placeholder {
    color: rgba(0,0,0,0.3);
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  .nodisplay {
    display: none !important;
  }

  .invisible {
    opacity: 0;
    visibility: invisible;
    pointer-events: none;
  }

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.grid.grid_16}px 0 ${props => props.theme.grid.grid_32}px;
    min-height: 92px;
  }

  .modal-button-group {
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 78px;
    padding: 0 ${props => props.theme.grid.grid_16}px;
    background: ${props => props.theme.colors.white};
    border-top: solid 1px ${props => props.theme.colors.black10};
  }
`

export default BaseStyles
