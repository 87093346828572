const table = `

-- attribute_type defines a attribute type. Each attribute type has a defined
-- type which defines how the input is formated/sanitized. This is used by both
-- attribute_definition and a custom_attribute
CREATE TABLE IF NOT EXISTS attribute_type (
  id    INTEGER NOT NULL PRIMARY KEY,
  value TEXT NOT NULL UNIQUE
);

-- attribute_check_type defines the type of check performed against an attribute.
-- These are used by both custom_attribute_check and thing_definition_attribute_definition_check
CREATE TABLE IF NOT EXISTS attribute_check_type (
  id    INTEGER NOT NULL PRIMARY KEY,
  value TEXT NOT NULL UNIQUE
);

-- attribute_definition is the list of all the attributes we understand.
-- Things like 'Color', 'Price', 'Model #' etc...
--
-- These need to be defined ahead of time so we can query against them.
-- Then we can analyze and understand things like 'What type of pipes do you have'
-- or 'What color is the wall'? Giving users suggestions
CREATE TABLE IF NOT EXISTS attribute_definition (
  id    INTEGER NOT NULL PRIMARY KEY,
  type  INTEGER NOT NULL,
  value TEXT NOT NULL UNIQUE,
  FOREIGN KEY (type) REFERENCES attribute_type(id)
);

-- thing_definition_attribute_definition_check defines logic that is executed against each
-- attribute defined by a user. These checks are used to notify users about maintenance or
-- recalls against items they own.
CREATE TABLE IF NOT EXISTS thing_definition_attribute_definition_check (
  id                   INTEGER PRIMARY KEY,
  definition           INTEGER NOT NULL,
  type                 INTEGER NOT NULL,
  severity             TEXT CHECK(severity IN ('info', 'warn', 'alert')) NOT NULL,
  value                TEXT NOT NULL,
  message              TEXT NOT NULL,
  FOREIGN KEY (definition) REFERENCES thing_definition_attribute_definition(id),
  FOREIGN KEY (type)       REFERENCES attribute_check_type(id),
  UNIQUE(definition, type, severity, value) ON CONFLICT IGNORE
);

-- thing_definition is the last of all things we understand.
-- These are all the things that users could add to their homes
CREATE TABLE IF NOT EXISTS thing_definition (
  id    INTEGER PRIMARY KEY,
  value TEXT NOT NULL UNIQUE
);

-- thing_definition_attribute_definition is the mapping of thing <-> attribute
-- Some attributes like 'Install Date' are applicable to many things
CREATE TABLE IF NOT EXISTS thing_definition_attribute_definition (
  id                   INTEGER PRIMARY KEY,
  thing_definition     INTEGER NOT NULL,
  attribute_definition INTEGER NOT NULL,
  FOREIGN KEY (thing_definition) REFERENCES thing_definition (id),
  FOREIGN KEY (attribute_definition) REFERENCES attribute_definition (id),
  UNIQUE(thing_definition, attribute_definition) ON CONFLICT IGNORE
);

-- thing contains everything created by the user
CREATE TABLE IF NOT EXISTS thing (
  id         INTEGER PRIMARY KEY,
  definition INTEGER,
  value      TEXT NOT NULL,
  deleted    BOOLEAN NOT NULL CHECK (deleted IN (0, 1)),
  FOREIGN KEY (definition) REFERENCES thing_definition (id)
);

-- thing_relationship allows us express relationship between things.
-- We could express concepts like "'Couch' is in 'Hallway'"
CREATE TABLE IF NOT EXISTS thing_relationship (
  id     INTEGER NOT NULL PRIMARY KEY,
  self   INTEGER NOT NULL,
  other  INTEGER NOT NULL,
  type   TEXT CHECK(type IN ('child')) NOT NULL,
  FOREIGN KEY (self) REFERENCES thing (id),
  FOREIGN KEY (other) REFERENCES thing (id)
);

-- attribute expresses something like 'Color', 'Price', 'Model #' etc...
-- against a Thing. All attributes are defined in attribute_definition
CREATE TABLE IF NOT EXISTS attribute (
  id                   INTEGER PRIMARY KEY,
  thing                INTEGER NOT NULL,
  attribute_definition INTEGER NOT NULL,
  value                TEXT NOT NULL,
  FOREIGN KEY (thing) REFERENCES thing (id),
  FOREIGN KEY (attribute_definition) REFERENCES attribute_definition (id),
  UNIQUE(thing, attribute_definition) ON CONFLICT REPLACE
);

-- project is the list of all the projects that someone could start
CREATE TABLE IF NOT EXISTS project (
  id               INTEGER PRIMARY KEY,
  value            TEXT NOT NULL UNIQUE,
  thing_definition INTEGER NOT NULL,
  FOREIGN KEY (thing_definition) REFERENCES thing_definition (id)
);

-- project_alternate_value has all the different titles for a project. When searching
-- people might use region specific terms or nicknames for projects
CREATE TABLE IF NOT EXISTS project_alternate_value (
  id        INTEGER PRIMARY KEY,
  value     TEXT NOT NULL UNIQUE,
  project   INTEGER NOT NULL,
  FOREIGN KEY (project) REFERENCES project (id)
);

-- project_solution contains all the solutions for a project.
-- These give users all the options to fix their project.
CREATE TABLE IF NOT EXISTS project_solution (
  id        INTEGER PRIMARY KEY,
  value     TEXT NOT NULL UNIQUE,
  project   INTEGER NOT NULL,
  FOREIGN KEY (project) REFERENCES project (id)
);

-- project_session is created by a user. This is a instance of a project
-- and the thing the project was performed upon.
CREATE TABLE IF NOT EXISTS project_session (
  id        INTEGER PRIMARY KEY,
  project   INTEGER NOT NULL,
  thing     INTEGER NOT NULL,
  FOREIGN KEY (project) REFERENCES project (id),
  FOREIGN KEY (thing)   REFERENCES thing (id)
);

-- state is a key/value store that stores arbitrary values. Useful to store
-- singular events and other things that don't need a dedicated table
CREATE TABLE IF NOT EXISTS state (
  id        INTEGER PRIMARY KEY,
  key       TEXT NOT NULL,
  value     TEXT NOT NULL,
  UNIQUE(key) ON CONFLICT REPLACE
);

-- custom_attribute are attributes that are defined directly against a item. This will
-- allow users to store details in any way they like. Things in their House will have
-- details we can't anticipate
CREATE TABLE IF NOT EXISTS custom_attribute (
  id                   INTEGER PRIMARY KEY,
  thing                INTEGER NOT NULL,
  type                 INTEGER NOT NULL,
  label                TEXT NOT NULL,
  value                TEXT NOT NULL,
  FOREIGN KEY (thing) REFERENCES thing (id),
  FOREIGN KEY (type) REFERENCES attribute_type (id),
  UNIQUE(thing, type, label) ON CONFLICT REPLACE
);
`

const view = `
-- All undeleted items
DROP VIEW IF EXISTS thing_view;
CREATE VIEW thing_view AS
SELECT
  thing.id AS thing_id,
  thing.value AS thing_value,
  thing.definition AS thing_definition,
  thing_definition.value AS thing_definition_value
FROM
  thing AS thing
LEFT JOIN
  thing_definition ON thing.definition = thing_definition.id
WHERE
  thing.deleted = FALSE;

-- All AttributeDefinitions associated with a ThingDefinition
DROP VIEW IF EXISTS thing_definition_attribute_definition_view;
CREATE VIEW thing_definition_attribute_definition_view AS
SELECT
  thing_definition_attribute_definition.id AS thing_definition_attribute_definition_id,
  thing_definition.value AS thing_definition_value,
  attribute_definition.value AS attribute_definition_value
FROM
  thing_definition_attribute_definition
JOIN
  attribute_definition ON thing_definition_attribute_definition.attribute_definition = attribute_definition.id
JOIN
  thing_definition     ON thing_definition_attribute_definition.thing_definition = thing_definition.id;

-- All relationships and the items they are associated with
DROP VIEW IF EXISTS thing_relationship_view;
CREATE VIEW thing_relationship_view AS
SELECT
  thing_relationship.id AS thing_relationship_id,
  thing_relationship.type AS thing_relationship_type,
  self.thing_id AS self_id,
  self.thing_value AS self_value,
  self_definition.id AS self_definition_id,
  self_definition.value AS self_definition_value,
  other.thing_id AS other_id,
  other.thing_value AS other_value,
  other_definition.id AS other_definition_id,
  other_definition.value AS other_definition_value
FROM
  thing_relationship
JOIN
  thing_view AS self on thing_relationship.self = self.thing_id
LEFT JOIN
  thing_definition AS self_definition on self.thing_definition = self_definition.id
JOIN
  thing_view AS other on thing_relationship.other = other.thing_id
LEFT JOIN
  thing_definition AS other_definition on other.thing_definition = other_definition.id;

-- All ProjectSessions and the Thing and ThingDefinition normalized
DROP VIEW IF EXISTS project_sessions_view;
CREATE VIEW project_sessions_view AS
SELECT
  project_session.id AS project_session_id,
  thing.id AS thing_id,
  thing.value AS thing_value,
  thing_definition.id AS thing_definition_id,
  thing_definition.value AS thing_definition_value,
  project.id AS project_id,
  project.value AS project_value
FROM
  project_session
JOIN
  project ON project_session.project = project.id
JOIN
  thing ON project_session.thing = thing.id
JOIN
  thing_definition ON thing.definition = thing_definition.id;

-- All AttributeDefinitions for ThingDefinition
DROP VIEW IF EXISTS thing_definition_attributes_view;
CREATE VIEW thing_definition_attributes_view AS
SELECT
    attribute_definition.id AS attribute_definition_id,
    attribute_definition.value AS attribute_definition_value,
    attribute_type.id AS attribute_type_id,
    attribute_type.value AS attribute_type_value,
    thing_definition.id AS thing_definition_id,
    thing_definition.value AS thing_definition_value
FROM
    thing_definition_attribute_definition
JOIN
  attribute_definition ON thing_definition_attribute_definition.attribute_definition = attribute_definition.id
JOIN
  attribute_type ON attribute_definition.type = attribute_type.id
JOIN
  thing_definition ON thing_definition_attribute_definition.thing_definition = thing_definition.id;

-- All Attributes for a Thing
DROP VIEW IF EXISTS thing_attribute_view;
CREATE VIEW thing_attribute_view AS
SELECT
  attribute.id AS attribute_id,
  attribute.value AS attribute_value,
  attribute_definition.id AS attribute_definition_id,
  attribute_definition.value AS attribute_definition_value,
  attribute_type.id AS attribute_type_id,
  attribute_type.value AS attribute_type_value,
  thing_definition.id AS thing_definition_id,
  thing_definition.value AS thing_definition_value,
  thing.id AS thing_id,
  thing.value as thing_value
FROM
  attribute
JOIN
  attribute_definition ON attribute.attribute_definition = attribute_definition.id
JOIN
  attribute_type ON attribute_definition.type = attribute_type.id
JOIN
  thing ON attribute.thing = thing.id
JOIN
  thing_definition ON thing.definition = thing_definition.id;

-- All Custom Attributes for a Thing
DROP VIEW IF EXISTS thing_custom_attribute_view;
CREATE VIEW thing_custom_attribute_view AS
SELECT
  custom_attribute.id AS custom_attribute_id,
  custom_attribute.label AS custom_attribute_label,
  custom_attribute.value AS custom_attribute_value,
  attribute_type.id AS attribute_type_id,
  attribute_type.value AS attribute_type_value,
  thing_definition.id AS thing_definition_id,
  thing_definition.value AS thing_definition_value,
  thing.id AS thing_id,
  thing.value as thing_value
FROM
  custom_attribute
JOIN
  attribute_type ON custom_attribute.type = attribute_type.id
JOIN
  thing ON custom_attribute.thing = thing.id
JOIN
  thing_definition ON thing.definition = thing_definition.id;

-- All ProjectSolutions for a Project
DROP VIEW IF EXISTS project_solutions_view;
CREATE VIEW project_solutions_view AS
SELECT
  project_solution.id AS project_solution_id,
  project_solution.value AS project_solution_value,
  project.id AS project_id,
  project.value AS project_value,
  thing_definition.id AS thing_definition_id,
  thing_definition.value AS thing_definition_value
FROM
  project_solution
JOIN
  project ON project_solution.project = project.id
JOIN
  thing_definition ON project.thing_definition = thing_definition.id;

-- Projects and the associated Thing
DROP VIEW IF EXISTS projects_thing_view;
CREATE VIEW projects_thing_view AS
SELECT
  project.id AS project_id,
  project.value AS project_value,
  thing_definition.id AS thing_definition_id,
  thing_definition.value AS thing_definition_value
FROM
  project
JOIN
  thing_definition ON project.thing_definition = thing_definition.id;

-- All Checks and their associated info
DROP VIEW IF EXISTS thing_definition_attribute_definition_check_view;
CREATE VIEW thing_definition_attribute_definition_check_view AS
SELECT
  thing_definition_attribute_definition_check.id,
  thing_definition_attribute_definition_check.severity,
  thing_definition_attribute_definition_check.value,
  thing_definition_attribute_definition_check.message,
  attribute_check_type.id,
  attribute_check_type.value,
  thing.id,
  thing.value,
  thing_definition.id,
  thing_definition.value,
  attribute.id,
  attribute.value,
  attribute_definition.id,
  attribute_definition.value,
  attribute_type.id,
  attribute_type.value
FROM
  thing_definition_attribute_definition_check
JOIN
  thing ON thing_definition_attribute_definition.thing_definition = thing.definition
JOIN
  attribute_check_type ON thing_definition_attribute_definition_check.type = attribute_check_type.id
JOIN
  thing_definition ON thing.definition = thing_definition.id
JOIN
  attribute_definition ON attribute.attribute_definition = attribute_definition.id
JOIN
  attribute ON thing.id = attribute.thing AND thing_definition_attribute_definition.attribute_definition = attribute.attribute_definition
JOIN
  attribute_type ON attribute_definition.type = attribute_type.id
JOIN
  thing_definition_attribute_definition ON thing_definition_attribute_definition_check.definition = thing_definition_attribute_definition.id;
`

const data = `
INSERT OR IGNORE INTO attribute_type (value)
VALUES
  ('text'),
  ('date'),
  ('number'),
  ('textarea');

INSERT OR IGNORE INTO attribute_check_type (value)
VALUES
  ('diff'),
  ('equal');

INSERT OR IGNORE INTO attribute_definition (type, value)
VALUES
  ((SELECT id from attribute_type WHERE value = 'date'), 'Install Date'),
  ((SELECT id from attribute_type WHERE value = 'date'), 'Deep Clean'),
  ((SELECT id from attribute_type WHERE value = 'date'), 'Defrosted'),
  ((SELECT id from attribute_type WHERE value = 'text'), 'Model Number'),
  ((SELECT id from attribute_type WHERE value = 'textarea'), 'Notes');

INSERT OR IGNORE INTO thing_definition (value)
VALUES
  ('Central Air Conditioner'),
  ('Clothes Washer'),
  ('Dishwasher'),
  ('Refrigerator'),
  ('Room'),
  ('Smoke Detector'),
  ('Stove'),
  ('Toilet');

INSERT OR IGNORE INTO project (value, thing_definition)
VALUES
  ('Deep Clean Refrigerator', (SELECT id FROM thing_definition WHERE value = 'Refrigerator')),
  ('Defrost the freezer', (SELECT id FROM thing_definition WHERE value = 'Refrigerator')),

  ('Replace batteries', (SELECT id FROM thing_definition WHERE value = 'Smoke Detector')),
  ('Test detectors', (SELECT id FROM thing_definition WHERE value = 'Smoke Detector'));

INSERT OR IGNORE INTO project_solution (project, value)
VALUES
  ((SELECT id FROM project WHERE value = 'Deep Clean Refrigerator'), 'Cleaning out your Refrigerator is good for your health and the health of your Refrigerator.'),
  ((SELECT id FROM project WHERE value = 'Deep Clean Refrigerator'), 'Get rid of gunk from fridge walls with a sponge'),
  ((SELECT id FROM project WHERE value = 'Deep Clean Refrigerator'), 'Remove all food. Throw away expired items.'),
  ((SELECT id FROM project WHERE value = 'Deep Clean Refrigerator'), 'Remove all shelves and bins. Wash them with soapy water. Do not put them back until dry'),

  ((SELECT id FROM project WHERE value = 'Defrost the freezer'), 'Defrosting your freezer helps it cool more efficiently and keeps ice at bay.'),
  ((SELECT id FROM project WHERE value = 'Defrost the freezer'), 'Remove all food. Throw away expired items.'),
  ((SELECT id FROM project WHERE value = 'Defrost the freezer'), 'Defrost and catch the ice melt. Lay towels in front of the door'),
  ((SELECT id FROM project WHERE value = 'Defrost the freezer'), 'Remove all shelves and bins. Wash them with soapy water. Do not put them back until dry');


INSERT OR IGNORE INTO thing_definition_attribute_definition (thing_definition, attribute_definition)
VALUES
        ((SELECT id FROM thing_definition WHERE value = 'Refrigerator'), (SELECT id FROM attribute_definition WHERE value = 'Deep Clean')),

        ((SELECT id FROM thing_definition WHERE value = 'Central Air Conditioner'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Clothes Washer'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Dishwasher'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Refrigerator'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Smoke Detector'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Stove'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),
        ((SELECT id FROM thing_definition WHERE value = 'Toilet'), (SELECT id FROM attribute_definition WHERE value = 'Model Number')),

        ((SELECT id FROM thing_definition WHERE value = 'Central Air Conditioner'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Clothes Washer'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Dishwasher'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Refrigerator'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Smoke Detector'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Stove'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),
        ((SELECT id FROM thing_definition WHERE value = 'Toilet'), (SELECT id FROM attribute_definition WHERE value = 'Install Date')),

        ((SELECT id FROM thing_definition WHERE value = 'Central Air Conditioner'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Clothes Washer'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Dishwasher'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Refrigerator'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Smoke Detector'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Stove'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Toilet'), (SELECT id FROM attribute_definition WHERE value = 'Notes')),
        ((SELECT id FROM thing_definition WHERE value = 'Window'), (SELECT id FROM attribute_definition WHERE value = 'Notes'));

INSERT INTO thing_definition_attribute_definition_check (definition, type, severity, value, message)
VALUES
  ((SELECT thing_definition_attribute_definition_id
    FROM thing_definition_attribute_definition_view
    WHERE thing_definition_value = 'Refrigerator' AND attribute_definition_value = 'Deep Clean'),
    (SELECT id from attribute_check_type WHERE value = 'diff'), 'alert', '365', 'Refrigerator should be deep cleaned yearly');
`

const schema = table + view + data

// Modifications that require breaking changes. Indexed by version
const migrations = [
  `
  PRAGMA user_version = 1
  `
]

export {
  schema,
  migrations
}
