import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fuse from 'fuse.js'

import detectOutsideClick from '../DetectOutsideClick'
import Button from '../Button'
import CheckIcon from '../Icons/Check'
import PlusIcon from '../Icons/Plus'
import QuestionIcon from '../Icons/Question'
import SearchInput from '../SearchInput'
import iconForThingDefinition from '../../styles/icon'

import DB from '../../db'
import { ThingDefinition, Thing } from '../../db/types'

const ModalHeader = styled.div`
  .itemSearchInput {
    margin-top: ${props => props.theme.grid.grid_16}px;
  }
`

const ThingDefinitionListItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.grid.grid_8}px;
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};

  span {
    flex: 2;
    display: inline-block;
    margin-left: ${props => props.theme.grid.grid_16}px;
  }

  .check {
    --size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: ${props => props.theme.colors.white};
    padding: ${props => props.theme.grid.grid_8 / 2}px;
    margin-right: ${props => props.theme.grid.grid_8 / 2}px;
    opacity: 0;
    width: var(--size);
    border-radius: var(--size);
    background: ${props => props.theme.colors.green};
  }

  &.active {
    .check {
      opacity: 1;
    }
  }

  &:hover {
    background: ${props => props.theme.colors.black3};
  }
`

const ItemIcon = styled.div`
  --size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  min-width: var(--size);
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;

  .active & {
    border-color: ${props => props.theme.colors.green_fade};
    background: ${props => props.theme.colors.green_fade};
  }

  &.custom {
    border-color: ${props => props.theme.colors.green};
  }

  .icon {
    --size: 14px;
    fill: ${props => props.theme.colors.green};
    height: var(--size);
    width: var(--size);
  }
`

const ItemRadio = styled.input`
  display: none;
`

const CustomThingCover = styled.span`
  display: inline-block;
  margin-left: ${props => props.theme.grid.grid_8}px;
`

const CustomThingInput = styled.input`
  margin-left: ${props => props.theme.grid.grid_8}px;
`

interface CreateThingFormProps {
  db: DB
  onFormSubmit: () => void
  onClick?: () => void
  presetType?: ThingDefinition
  isHidden?: boolean
}

function CreateThingForm ({ db, onFormSubmit, onClick, presetType, isHidden }: CreateThingFormProps): JSX.Element | null {
  const [customThingCoverHidden, setcustomThingCoverHidden] = React.useState(true)
  const customThingCoverRef = React.useRef<HTMLDivElement>(null)
  const customThingInputRef = React.useRef<HTMLInputElement>(null)
  const [thingDefinitions, setThingDefinitions] = React.useState<ThingDefinition[]>([])
  const [filteredThingDefinitions, setFilteredThingDefinitions] = React.useState<ThingDefinition[]>([])
  const [thingDefinitionSearch, setThingDefinitionSearch] = React.useState('')
  const [selectedThings, setSelectedThings] = React.useState(new Map<number, boolean>())
  const [roomList, setRoomList] = React.useState<Thing[]>([])
  const [customNewRoom, setCustomNewRoom] = React.useState('')
  const [newThingRoom, setNewThingRoom] = React.useState<Thing | undefined>(undefined)
  const [newThingRoomSearch, setNewThingRoomSearch] = React.useState('')
  const [customThing, setCustomThing] = React.useState('')
  const [customThings, setCustomThings] = React.useState<string[]>([])

  React.useEffect(() => {
    if (thingDefinitionSearch === '') {
      setFilteredThingDefinitions(thingDefinitions)
      return
    }

    const options: Fuse.IFuseOptions<ThingDefinition> = {
      keys: ['value']
    }

    const fuseSearch = new Fuse(thingDefinitions, options)
    setFilteredThingDefinitions(fuseSearch.search(thingDefinitionSearch).map(i => i.item))
  }, [thingDefinitions, thingDefinitionSearch])

  React.useEffect(() => {
    customThingInputRef.current?.focus()
  })

  React.useEffect(() => {
    if (newThingRoom === undefined) {
      setNewThingRoom(roomList.find(r => r.value === newThingRoomSearch))
    }
  }, [newThingRoomSearch, roomList, setNewThingRoom, newThingRoom])

  React.useEffect(() => {
    setTimeout(() => {
      setCustomNewRoom('')
      setNewThingRoomSearch('')
      setNewThingRoom(undefined)
      setThingDefinitionSearch('')
      setSelectedThings(new Map<number, boolean>())
      setCustomThing('')
      setCustomThings([])
    }, 200)
  }, [isHidden])

  React.useEffect(() => {
    db.getThings('Room').then(rooms => {
      setRoomList(rooms)
    }).catch(window.alert)
  }, [db, newThingRoom, setRoomList])

  const populateThingDefinitions = async (): Promise<void> => {
    const newValue = (await db.getThingDefinitions()).filter(t => t.value !== 'Room')
    if (!_.isEqual(newValue, thingDefinitions)) {
      setThingDefinitions(newValue)
    }
  }
  populateThingDefinitions().catch(window.alert)

  React.useEffect(() => {
    if (newThingRoom === undefined || presetType === undefined) {
      return
    }

    db.createThing({
      thingDefinitionId: presetType.id,
      value: presetType.value,
      room: newThingRoom
    }).then(() => {
      onFormSubmit()
    }).catch(window.alert)
  }, [newThingRoom, presetType])

  detectOutsideClick(customThingCoverRef, () => {
    setcustomThingCoverHidden(true)
  })

  const createThings = async (): Promise<void> => {
    if (newThingRoom === undefined) {
      return
    }

    const newThingDefinitions: ThingDefinition[] = []
    selectedThings.forEach((_, i) => {
      const newThingDefinition = thingDefinitions.find(thingDefinition => thingDefinition.id === i)
      if (newThingDefinition !== undefined) {
        newThingDefinitions.push(newThingDefinition)
      }
    })

    for (const newThingDefinition of newThingDefinitions) {
      await db.createThing({
        thingDefinitionId: newThingDefinition.id,
        value: newThingDefinition.value,
        room: newThingRoom
      })
    }

    for (const newCustomThing of customThings) {
      await db.createThing({
        value: newCustomThing,
        room: newThingRoom
      })
    }

    onFormSubmit()
  }

  const createCustomThing = (): void => {
    if (customThing.length === 0) {
      return
    }

    setCustomThings([...customThings, customThing])
    setCustomThing('')
  }

  const createNewRoom = async (): Promise<void> => {
    const thingDefinitions = await db.getThingDefinitions()
    const roomThingDefinition = thingDefinitions.find(t => t.value === 'Room')
    if (roomThingDefinition === undefined) {
      return
    }

    await db.createThing({
      thingDefinitionId: roomThingDefinition.id,
      value: customNewRoom
    })
    const rooms = await db.getThings('Room')
    setNewThingRoom(rooms.find(t => t.value === customNewRoom))
  }

  if (newThingRoom === undefined) {
    return (
      <>
        <ModalHeader className='modalHeader'>
          <h4>Which room?</h4>
        </ModalHeader>

        <div className='modalBody lowTopPad'>
          <div className='fieldGroup'>
            {roomList.length !== 0 && <SearchInput
              label={'Use existing room'}
              itemList={roomList.map(r => r.value)}
              placeHolder={'Choose a room'}
              inputValue={newThingRoomSearch}
              setInputValue={setNewThingRoomSearch}
            />}
          </div>

          <div className='fieldGroup'>
            <label> Create a new one </label>
            <input
              value={customNewRoom}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCustomNewRoom(e.target.value) }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' && customNewRoom.length !== 0) {
                  createNewRoom().catch(window.alert)
                }
              }}
            />
          </div>
          <Button
              onClick={ () => {
                if (customNewRoom.length !== 0) {
                  createNewRoom().catch(window.alert)
                }
              }}
              className=''
              text='Create Room'
            />
        </div>
      </>
    )
  }

  return (
  <>
    <ModalHeader className='modalHeader'>
      <h4>Add items to your {newThingRoom.value} </h4>
      <input type="search"
        placeholder="Search..."
        value={thingDefinitionSearch}
        className='itemSearchInput'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setThingDefinitionSearch(e.target.value) }}
      />
    </ModalHeader>

    <div className='modalBody lowAllPad'>

      <ThingDefinitionListItem
        ref={customThingCoverRef}
        onClick={ () => { setcustomThingCoverHidden(customThingCoverHidden => !customThingCoverHidden) }}
      >
        <ItemIcon
          className='custom'
          onClick={createCustomThing}
        >
          <PlusIcon />
        </ItemIcon>
        <CustomThingCover className={`${customThingCoverHidden ? '' : 'nodisplay'}`}>Custom</CustomThingCover>
        <CustomThingInput
          value={customThing}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCustomThing(e.target.value) }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              createCustomThing()
            }
            if (e.key === 'Escape') {
              setcustomThingCoverHidden(true)
            }
          }}
          placeholder="Custom"
          ref={customThingInputRef}
          onClick={e => { e.stopPropagation() }}
          className={`${customThingCoverHidden ? 'nodisplay' : ''}`}
          >
        </CustomThingInput>
      </ThingDefinitionListItem>

      {customThings.map((t, i) => {
        return (
          <ThingDefinitionListItem
            key={i}
            className='active'
          >
            <ItemIcon className='custom' >
              <QuestionIcon />
            </ItemIcon>
            <span>{t}</span>
            <CheckIcon className='check' />
          </ThingDefinitionListItem>
        )
      })}

      {filteredThingDefinitions.map((t, i) => {
        return (
          <ThingDefinitionListItem
            key={i}
            onClick={ () => {
              const currentValue = selectedThings.get(t.id) ?? false
              setSelectedThings(new Map(selectedThings.set(t.id, !currentValue)))
            }}
            className={selectedThings.get(t.id) === true ? 'active' : ''}
          >
            <ItemIcon className='itemIcon'>
              <FontAwesomeIcon icon={iconForThingDefinition(t.value)} />
            </ItemIcon>
            <span>{t.value}</span>
            <ItemRadio
              type="radio"
              className='selected-radio'
              checked={selectedThings.get(t.id) ?? false}
              onChange={() => {}}
            />
            <CheckIcon className='check' />
          </ThingDefinitionListItem>
        )
      })}
    </div>

    <div className='modalFooter'>
      <Button className={''} onClick={() => { createThings().catch(window.alert) }} text='Add Items' />
    </div>
  </>
  )
}

export default CreateThingForm
