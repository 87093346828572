import React from 'react'
import styled from 'styled-components'
import detectOutsideClick from '../DetectOutsideClick'
import { GoogleLogin } from '@react-oauth/google'

interface UserAccountMenuProps {
  onClick?: () => void
}

const UserAccountMenuWrap = styled.span`
  position: relative;
  align-self: start;

  .icon {
    height: 20px;
  }
`

const UserAccountMenuAvatar = styled.div`
  --size: 46px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  margin-left: ${props => props.theme.grid.grid_8}px;
  background: ${props => props.theme.colors.black10};
  border-radius: var(--size);
  cursor: pointer;
`

const UserAccountMenuDropdown = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + ${props => props.theme.grid.grid_8 / 2}px);
  right: 0;
  padding: ${props => props.theme.grid.grid_8}px;
  min-width: 200px;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};
  transition: ${props => props.theme.transitions.quick};
  z-index: 2000;

  &.hidden {
    top: calc(100% - ${props => props.theme.grid.grid_8}px);
  }
`

const UserAccountMenuItem = styled.li`
  padding: ${props => props.theme.grid.grid_8}px;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.black3};
  }

  &.delete {
    &:hover {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.red_fade};
    }
  }
`

function UserAccountMenu ({ onClick }: UserAccountMenuProps): JSX.Element | null {
  const [userAccountMenuHidden, setUserAccountMenuDropdownHidden] = React.useState(true)
  const userAccountMenuRef = React.useRef<HTMLSpanElement>(null)

  detectOutsideClick(userAccountMenuRef, () => {
    setUserAccountMenuDropdownHidden(true)
  })

  return (
    <UserAccountMenuWrap ref={userAccountMenuRef} onClick={ () => { setUserAccountMenuDropdownHidden(userAccountMenuHidden => !userAccountMenuHidden) }}>
      <UserAccountMenuAvatar />
      <UserAccountMenuDropdown className={`${userAccountMenuHidden ? 'hidden' : ''}`} onClick={e => { e.stopPropagation() }}>
        <UserAccountMenuItem>Logout</UserAccountMenuItem>
        <UserAccountMenuItem>
          <GoogleLogin
            onSuccess={credentialResponse => {
              console.log(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed')
            }}
          />
        </UserAccountMenuItem>

      </UserAccountMenuDropdown>
    </UserAccountMenuWrap>
  )
}

export default UserAccountMenu
