import React from 'react'
import styled from 'styled-components'

interface ModalProps {
  children: React.ReactNode
  isHidden: boolean
  className?: string
  onClick?: () => void
}

const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10vh ${props => props.theme.grid.grid_16}px ${props => props.theme.grid.grid_16}px;
  z-index: 9999;
  background: ${props => props.theme.colors.black10};
  visibility: visible;
  opacity: 1;
  transition: ${props => props.theme.transitions.quick};

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: ${props => props.theme.grid.grid_32}px 0 0;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  max-height: 630px;
  margin: 0 auto;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.large}px;
  box-shadow: ${props => props.theme.shadows.large};
  transition: ${props => props.theme.transitions.quick};
  overflow: hidden;

  input,
  select,
  button {
    width: 100%;
  }

  &.onboarding {
    max-width: 520px;
  }

  .modalHeader {
    flex: 0 0 auto;
    padding: ${props => props.theme.grid.grid_24}px ${props => props.theme.grid.grid_32}px;
  }

  .modalBody {
    flex: 2;
    padding: ${props => props.theme.grid.grid_8}px ${props => props.theme.grid.grid_32}px ${props => props.theme.grid.grid_24}px;
    overflow: auto;

    &.lowTopPad {
      padding-top: ${props => props.theme.grid.grid_8}px;
    }
    
    &.lowAllPad {
      padding: ${props => props.theme.grid.grid_8}px ${props => props.theme.grid.grid_24}px;
    }
  }

  .modalFooter {
    display: flex;
    flex: 0 0 auto;
    padding: ${props => props.theme.grid.grid_16}px ${props => props.theme.grid.grid_24}px;
    border-top: solid 1px ${props => props.theme.colors.black10};
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    max-width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .hidden & {
      margin-bottom: -${props => props.theme.grid.grid_32}px;
    }
  }
`

function Modal ({ children, isHidden, className, onClick }: ModalProps): JSX.Element | null {
  return (
    <ModalComponent className={`${isHidden ? 'hidden' : ''}`} onClick={onClick}>
      <ModalContent onClick={e => { e.stopPropagation() }} className={className}>
        {children}
      </ModalContent>
    </ModalComponent>
  )
}

export default Modal
