import React from 'react'

export default function detectOutsideClick (ref: React.RefObject<HTMLSpanElement | null>, onOutsideClick: () => void): void {
  React.useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent): void => {
      if (ref.current !== null && !ref.current.contains(event.target as any)) {
        onOutsideClick()
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref])
}
