import React from 'react'
import styled from 'styled-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import CreateProject from './component/CreateProject'
import MyHouse from './component/MyHouse'
import Topbar from './component/Topbar'
import Project from './component/Project'
import Sidebar from './component/Sidebar'
import Schedule from './component/Schedule'
import Landing from './component/Landing'
import Login from './component/Login'
import Register from './component/Register'
import DB from './db'

const Body = styled.div`
  display: flex;
`

const Main = styled.div`
  padding-top: ${props => props.theme.header.height}px;
  margin-left: ${props => props.theme.header.width}px;
  width: 100%;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    margin-left: 0;
  }
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: calc(100vh - ${props => props.theme.header.height}px);
  width: 100%;
  padding: 0 ${props => props.theme.grid.grid_64}px;
  max-width: ${props => props.theme.breakpoints.desktop}px;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding: 0 ${props => props.theme.grid.grid_32}px;
  }
`

function App (): JSX.Element | null {
  if (window.location.hostname === 'yourhouseiq.com' || window.location.pathname === '/landing') {
    return <Landing />
  } else if (window.location.pathname === '/login') {
    return <Login />
  } else if (window.location.pathname === '/register') {
    return <Register />
  } else if (window.location.pathname === '/reset') {
    DB.reset(() => {
      window.location.pathname = '/'
    }, () => {
      window.alert('Failed to delete IndexDB')
    })
    return <> </>
  }

  const [db] = React.useState(new DB())
  return (
    <BrowserRouter>
      <Body>
        <Sidebar db={db} />
        <Main>
          <Topbar db={db} />
          <PageContainer>
            <Routes>
              <Route path='*' element={<MyHouse db={db} />} />
              <Route path='my-house' element={<MyHouse db={db} />} />
              <Route path='create-project/:id' element={<CreateProject db={db} />} />
              <Route path='project' element={<Project db={db} />} />
              <Route path='project/:id' element={<Project db={db} />} />
              <Route path='schedule' element={<Schedule db={db} />} />
            </Routes>
          </PageContainer>
        </Main>
      </Body>
    </BrowserRouter>
  )
}

export default App
