import React from 'react'

function Logo (): JSX.Element | null {
  return (
    <svg width="110" height="26" viewBox="0 0 110 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.9375H5.03406V10.6138H12.2097V2.9375H17.2437V22.5731H12.2097V14.7578H5.03406V22.5731H0V2.9375Z" fill="#267E78"/>
      <path d="M26.7758 22.9625C25.0885 22.9625 23.6423 22.6287 22.4371 21.9613C21.2504 21.2752 20.379 20.311 19.8227 19.0688C19.3777 18.0675 19.1552 16.9179 19.1552 15.62C19.1552 14.3406 19.3777 13.2003 19.8227 12.1991C20.379 10.9568 21.2504 9.9926 22.4371 9.30656C23.6423 8.62052 25.0885 8.2775 26.7758 8.2775C28.4631 8.2775 29.9001 8.62052 31.0868 9.30656C32.2734 9.97406 33.1449 10.929 33.7011 12.1713C34.1647 13.154 34.3965 14.3035 34.3965 15.62C34.3965 16.955 34.1647 18.1139 33.7011 19.0966C33.1449 20.3203 32.2734 21.2752 31.0868 21.9613C29.9001 22.6287 28.4631 22.9625 26.7758 22.9625ZM26.7758 19.2078C27.9254 19.2078 28.7042 18.7721 29.1121 17.9006C29.2419 17.6225 29.3253 17.3073 29.3624 16.955C29.418 16.5842 29.4458 16.1392 29.4458 15.62C29.4458 14.5817 29.3346 13.8307 29.1121 13.3672C28.7227 12.4957 27.944 12.06 26.7758 12.06C25.6262 12.06 24.8475 12.4957 24.4396 13.3672C24.2171 13.8864 24.1058 14.6373 24.1058 15.62C24.1058 16.5842 24.2171 17.3444 24.4396 17.9006C24.8475 18.7721 25.6262 19.2078 26.7758 19.2078Z" fill="#267E78"/>
      <path d="M49.7472 8.69469V22.5731H45.1025V20.8766C44.6019 21.5626 43.9993 22.091 43.2947 22.4619C42.6087 22.8142 41.7928 22.9903 40.8472 22.9903C39.2897 22.9903 38.0752 22.5268 37.2038 21.5997C36.3509 20.6726 35.9244 19.3469 35.9244 17.6225V8.69469H40.5691V16.6213C40.5691 17.5298 40.7452 18.188 41.0975 18.5959C41.4498 19.0039 41.9968 19.2078 42.7385 19.2078C44.3145 19.2078 45.1025 18.2993 45.1025 16.4822V8.69469H49.7472Z" fill="#267E78"/>
      <path d="M57.7633 23.0738C56.28 23.0738 55.0284 22.842 54.0086 22.3784C52.9888 21.8964 52.275 21.1918 51.8671 20.2647C51.5704 19.6157 51.4221 18.8648 51.4221 18.0119H55.9833C55.9833 18.4754 56.0482 18.837 56.178 19.0966C56.3078 19.3932 56.5025 19.6157 56.7621 19.7641C57.0402 19.9124 57.3925 19.9866 57.8189 19.9866C58.1898 19.9866 58.4957 19.9309 58.7367 19.8197C58.9963 19.7084 59.1725 19.5416 59.2652 19.3191C59.3393 19.1707 59.3764 18.9946 59.3764 18.7906C59.3764 18.5867 59.3393 18.4198 59.2652 18.29C59.1539 18.049 58.9685 17.8543 58.7089 17.7059C58.4493 17.5576 58.1063 17.4185 57.6799 17.2887C57.2534 17.159 56.7991 17.0384 56.3171 16.9272C55.3158 16.6676 54.4629 16.3431 53.7583 15.9538C53.0723 15.5644 52.5624 15.0081 52.2286 14.285C52.0247 13.7844 51.9227 13.2559 51.9227 12.6997C51.9227 12.0322 52.0432 11.4296 52.2842 10.8919C52.6551 10.039 53.3318 9.39 54.3146 8.945C55.2973 8.5 56.4654 8.2775 57.8189 8.2775C59.1725 8.2775 60.3221 8.49073 61.2677 8.91719C62.2318 9.34365 62.9086 9.98333 63.298 10.8363C63.5576 11.411 63.6874 12.06 63.6874 12.7831H59.293C59.293 12.3938 59.2374 12.0785 59.1261 11.8375C58.8851 11.3369 58.4215 11.0866 57.7355 11.0866C57.4203 11.0866 57.1514 11.1422 56.9289 11.2534C56.725 11.3461 56.5766 11.4852 56.4839 11.6706C56.4283 11.8004 56.4005 11.9488 56.4005 12.1156C56.4005 12.301 56.4283 12.4494 56.4839 12.5606C56.5952 12.8017 56.7991 13.0056 57.0958 13.1725C57.3925 13.3208 57.754 13.4599 58.1805 13.5897C58.6255 13.7195 59.0705 13.84 59.5155 13.9513C60.6465 14.2479 61.5736 14.6002 62.2967 15.0081C63.0384 15.416 63.5761 15.9816 63.9099 16.7047C64.1138 17.1311 64.2158 17.6781 64.2158 18.3456C64.2158 19.0873 64.0953 19.727 63.8542 20.2647C63.4278 21.2103 62.6768 21.9149 61.6014 22.3784C60.526 22.842 59.2466 23.0738 57.7633 23.0738Z" fill="#267E78"/>
      <path d="M74.8965 18.9853C75.0633 18.8555 75.1931 18.6979 75.2858 18.5125C75.3785 18.3085 75.4434 18.1046 75.4805 17.9006H80.1808C80.0696 19.2171 79.5411 20.3018 78.5955 21.1547C77.928 21.7666 77.1122 22.2208 76.148 22.5175C75.2024 22.8142 74.1641 22.9625 73.033 22.9625C71.3272 22.9625 69.8624 22.638 68.6386 21.9891C67.4149 21.3216 66.5249 20.3667 65.9686 19.1244C65.5236 18.1231 65.3011 16.9457 65.3011 15.5922C65.3011 14.8691 65.3568 14.2386 65.468 13.7009C65.5793 13.1447 65.7461 12.6163 65.9686 12.1156C66.5064 10.8919 67.3593 9.94625 68.5274 9.27875C69.6955 8.61125 71.0954 8.2775 72.7271 8.2775C74.3773 8.2775 75.7865 8.62052 76.9546 9.30656C78.1413 9.9926 79.0127 10.9475 79.569 12.1713C79.8656 12.8202 80.0603 13.4784 80.153 14.1459C80.2457 14.8134 80.2921 15.6849 80.2921 16.7603H70.0015C70.0571 17.4278 70.1683 17.9192 70.3352 18.2344C70.7246 19.1244 71.596 19.5694 72.9496 19.5694C73.8025 19.5694 74.4515 19.3747 74.8965 18.9853ZM72.8105 11.5594C72.1986 11.5594 71.6795 11.6799 71.253 11.9209C70.8451 12.1434 70.5392 12.4772 70.3352 12.9222C70.2425 13.1076 70.1683 13.293 70.1127 13.4784C70.0756 13.6453 70.0478 13.8585 70.0293 14.1181H75.564C75.5454 13.8585 75.5176 13.6453 75.4805 13.4784C75.4434 13.293 75.3785 13.1076 75.2858 12.9222C74.8779 12.0136 74.0528 11.5594 72.8105 11.5594Z" fill="#267E78"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M96.0156 0C103.385 0 109.359 5.9742 109.359 13.3437C109.359 16.8111 108.037 19.9696 105.869 22.3423L109.402 25.1424C109.759 25.4254 109.559 26 109.104 26H101.035L100.691 22.8584C100.431 20.4849 98.4263 18.6875 96.0386 18.6875C93.6316 18.6875 91.617 20.5133 91.381 22.9088L91.1007 25.7532C86.1634 23.796 82.6719 18.9776 82.6719 13.3437C82.6719 5.9742 88.6461 0 96.0156 0ZM100.234 13.3438C100.234 15.6737 98.3456 17.5625 96.0156 17.5625C93.6857 17.5625 91.7969 15.6737 91.7969 13.3438C91.7969 11.0138 93.6857 9.12501 96.0156 9.12501C98.3456 9.12501 100.234 11.0138 100.234 13.3438Z" fill="#267E78"/>
    </svg>
  )
}

export default Logo
