import { schema } from './schema'

import initSqlJs, { Database } from 'sql.js'

import sqlWasm from '../../node_modules/sql.js/dist/sql-wasm.wasm'

const dbName = 'houseIQ'
const indexName = 'sqlite'
const key = 1

export default class base {
  sqlite: Promise<Database>
  indexdb?: IDBDatabase

  static reset (onsuccess: () => void, onerror: () => void): void {
    const req = indexedDB.deleteDatabase(dbName)
    req.onsuccess = onsuccess
    req.onerror = onerror
  }

  constructor () {
    this.sqlite = new Promise((resolve) => {
      const r = indexedDB.open(dbName)

      r.onupgradeneeded = (e: any) => {
        if (e.target?.result !== undefined) {
          e.target.result.createObjectStore(indexName, { keyPath: 'key' })
        }
      }

      r.onsuccess = (e: any) => {
        this.indexdb = e.target.result

        const r = e.target.result
          .transaction(indexName, 'readwrite')
          .objectStore(indexName)
          .get(key)

        r.onsuccess = () => {
          initSqlJs({
            locateFile: file => sqlWasm
          }).then(SQL => {
            const db = new SQL.Database(r.result?.data)
            this.writeAndSave(db, schema, [])
              .then(() => { resolve(db) })
              .catch(window.alert)
          }).catch(window.alert)
        }
      }
    })

    this.sqlite.then(db => {
      (window as any).db = db
    }).catch(window.alert)
  }

  protected async writeAndSave (db: Database, sql: string, args: any): Promise<void> {
    if (args.length === 0) {
      await db.run(sql)
    } else {
      await db.run(sql, args)
    }

    if (this.indexdb != null) {
      this.indexdb.transaction(indexName, 'readwrite').objectStore(indexName).put({
        key,
        data: db.export()
      })
    }
  }

  async newestFromTable (tableName: string): Promise<number> {
    const res = (await this.sqlite).exec(`SELECT id FROM ${tableName} ORDER BY id DESC LIMIT 1`)
    return (res[0].values[0][0] as number)
  }

  protected async insertThing (value: string, thingDefinitionId?: number): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO thing (definition, value, deleted) VALUES (?,?, FALSE)', [thingDefinitionId === undefined ? null : thingDefinitionId, value])
  }

  protected async insertRelationship (self: number, other: number, type: string): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO thing_relationship (self, other, type) VALUES (?,?, ?)', [self, other, type])
  }
}
