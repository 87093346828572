import React from 'react'
import styled from 'styled-components'
import detectOutsideClick from '../DetectOutsideClick'
import MoreIcon from '../Icons/More'

interface ActionMenuProps {
  onDelete: () => void
  onRename: () => void
}

const ActionMenuWrap = styled.span`
  position: relative;
  align-self: start;

  .icon {
    height: 20px;
  }
`

const ActionMenuToggle = styled.span`
  --size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
`

const ActionMenuDropdown = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + ${props => props.theme.grid.grid_8 / 2}px);
  right: 0;
  padding: ${props => props.theme.grid.grid_8}px;
  min-width: 200px;
  background: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.black10};
  border-radius: ${props => props.theme.borderRadius.default}px;
  box-shadow: ${props => props.theme.shadows.large};
  transition: ${props => props.theme.transitions.quick};
  z-index: 2000;

  &.hidden {
    top: calc(100% - ${props => props.theme.grid.grid_8}px);
  }
`

const ActionsMenuItem = styled.li`
  padding: ${props => props.theme.grid.grid_8}px;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.black3};
  }

  &.delete {
    &:hover {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.red_fade};
    }
  }
`

function ActionMenu ({ onDelete, onRename }: ActionMenuProps): JSX.Element | null {
  const [actionMenuHidden, setActionMenuHidden] = React.useState(true)
  const actionMenuRef = React.useRef<HTMLSpanElement>(null)

  detectOutsideClick(actionMenuRef, () => {
    setActionMenuHidden(true)
  })

  return (
    <ActionMenuWrap ref={actionMenuRef} onClick={ () => { setActionMenuHidden(actionMenuHidden => !actionMenuHidden) }}>
      <ActionMenuToggle>
        <MoreIcon />
      </ActionMenuToggle>
      <ActionMenuDropdown className={`${actionMenuHidden ? 'hidden' : ''}`} onClick={e => { e.stopPropagation() }}>
        <ActionsMenuItem
          onClick={() => {
            setActionMenuHidden(true)
            onRename()
          }}
          className='rename'
        >
          Rename
        </ActionsMenuItem>
        <ActionsMenuItem
          onClick={() => {
            setActionMenuHidden(true)
            onDelete()
          }}
          className='delete'
        >
          Delete
        </ActionsMenuItem>
      </ActionMenuDropdown>
    </ActionMenuWrap>
  )
}

export default ActionMenu
