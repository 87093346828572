import React from 'react'
import styled from 'styled-components'
import Logo from '../Logo/Logo'

const Header = styled.header`
  background-color: rgba(255,255,255,0.9);
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: ${props => props.theme.header.height}px;
  padding: 0 ${props => props.theme.grid.grid_64}px;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 1000;

  svg {
    height: 24px;
    width: auto;
  }

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    padding: 0 ${props => props.theme.grid.grid_32}px;
  }
`

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${props => props.theme.breakpoints.large}px;
  width: 100%;
  margin: 0 auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
`

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  overflow: hidden;

  h3 {
    margin-bottom: ${props => props.theme.grid.grid_32}px;
  }
`

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${props => props.theme.grid.grid_32}px;

  @media (max-width: ${props => props.theme.mediaQueries.mobile}px) {
    flex-direction: column-reverse;
    padding: 0 ${props => props.theme.grid.grid_16}px;
  }
`

const SubText = styled.p`
  font-size: ${props => props.theme.text.size.small}px;
  padding-top: ${props => props.theme.grid.grid_32}px;
`

const Footer = styled.footer`
  flex: 0 0 auto;
  color: ${props => props.theme.colors.black50};
  font-size: ${props => props.theme.text.size.small}px;
  text-align: center;
  padding: ${props => props.theme.grid.grid_48}px ${props => props.theme.grid.grid_32}px;
`

function Login (): JSX.Element | null {
  return (
    <>
      <Content>
        <Header>
          <HeaderWrap>
            <Logo />
          </HeaderWrap>
        </Header>
        <Section>
          <SectionWrap>
            <h3>Welcome back!</h3>
            // Google button
            <SubText>Don't have an account? Create one</SubText>
          </SectionWrap>
        </Section>
        <Footer>
          <span>&copy; {new Date().getFullYear()} HouseIQ</span>
        </Footer>
      </Content>
    </>
  )
}

export default Login
