import _ from 'lodash'
import { QueryExecResult } from 'sql.js'

export interface ThingDefinition {
  id: number
  value: string
}

export interface AttributeType {
  id: number
  value: string
}

export interface AttributeCheckType {
  id: number
  value: string
}

export interface AttributeDefinition {
  id: number
  value: string
  type: AttributeType
  thingDefinition: ThingDefinition
}

export interface ThingDefinitionAttributeDefinitionCheck {
  id: number
  type: AttributeCheckType
  severity: string
  value: string
  message: string
  thing: Thing
  attribute: Attribute
}

export interface Attribute {
  id: number
  value: string
  thing: Thing
  attributeDefinition: AttributeDefinition
}

export interface ThingRelationship {
  id: number
  type: string
  self: Thing
  other: Thing
}

export interface Thing {
  id: number
  value: string
  thingDefinition?: ThingDefinition
}

export interface Project {
  id: number
  value: string
  thingDefinition: ThingDefinition
}

export interface ProjectAlternateValue {
  id: number
  project: number
  value: string
}

export interface ProjectSession {
  id: number
  thing: Thing
  project: Project
}

export interface ProjectSolution {
  id: number
  project: Project
  value: string
}

export interface State {
  id: number
  key: string
  value: string
}

export interface CustomAttribute {
  id: number
  thing: Thing
  type: AttributeType
  label: string
  value: string
}

// Only if both values are truthy return an object
function maybeCreateThingDefinition (id?: number, value?: string): ThingDefinition | undefined {
  if (_.isNil(id) || _.isNil(value)) {
    return
  }
  return { id, value }
}

export function serializeThings (res: QueryExecResult[]): Thing[] {
  const things: Thing[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      things.push({
        id: (val as any)[0],
        value: (val as any)[1],
        thingDefinition: maybeCreateThingDefinition((val as any)[2], (val as any)[3])
      })
    })
  }

  return things
}

export function serializeThingDefinitions (res: QueryExecResult[]): ThingDefinition[] {
  const thingDefinitions: ThingDefinition[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      thingDefinitions.push({
        id: (val as any)[0],
        value: (val as any)[1]
      })
    })
  }

  return thingDefinitions
}

export function serializeProject (res: QueryExecResult[]): Project[] {
  const projects: Project[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      projects.push({
        id: (val as any)[0],
        value: (val as any)[1],
        thingDefinition: {
          id: (val as any)[2],
          value: (val as any)[3]
        }
      })
    })
  }

  return projects
}

export function serializeProjectAlternateValues (res: QueryExecResult[]): ProjectAlternateValue[] {
  const projectValues: ProjectAlternateValue[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      projectValues.push({
        id: (val as any)[0],
        value: (val as any)[1],
        project: (val as any)[2]
      })
    })
  }

  return projectValues
}

export function serializeProjectSessions (res: QueryExecResult[]): ProjectSession[] {
  const projectSessions: ProjectSession[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      projectSessions.push({
        id: (val as any)[0],
        thing: {
          id: (val as any)[1],
          value: (val as any)[2],
          thingDefinition: maybeCreateThingDefinition((val as any)[3], (val as any)[4])
        },
        project: {
          id: (val as any)[5],
          value: (val as any)[6],
          thingDefinition: {
            id: (val as any)[3],
            value: (val as any)[4]
          }
        }
      })
    })
  }

  return projectSessions
}

export function serializeProjectSolutions (res: QueryExecResult[]): ProjectSolution[] {
  const projectSolutions: ProjectSolution[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      projectSolutions.push({
        id: (val as any)[0],
        value: (val as any)[1],
        project: {
          id: (val as any)[2],
          value: (val as any)[3],
          thingDefinition: {
            id: (val as any)[4],
            value: (val as any)[5]
          }
        }
      })
    })
  }

  return projectSolutions
}

export function serializeAttributeDefinitions (res: QueryExecResult[]): AttributeDefinition[] {
  const attributeDefinitions: AttributeDefinition[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      attributeDefinitions.push({
        id: (val as any)[0],
        value: (val as any)[1],
        type: {
          id: (val as any)[2],
          value: (val as any)[3]
        },
        thingDefinition: {
          id: (val as any)[4],
          value: (val as any)[5]
        }
      })
    })
  }

  return attributeDefinitions
}

export function serializeAttributes (res: QueryExecResult[]): Attribute[] {
  const attributes: Attribute[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      attributes.push({
        id: (val as any)[0],
        value: (val as any)[1],
        attributeDefinition: {
          id: (val as any)[2],
          value: (val as any)[3],
          type: {
            id: (val as any)[4],
            value: (val as any)[5]
          },
          thingDefinition: {
            id: (val as any)[6],
            value: (val as any)[7]
          }
        },
        thing: {
          id: (val as any)[8],
          value: (val as any)[9],
          thingDefinition: maybeCreateThingDefinition((val as any)[6], (val as any)[7])
        }
      })
    })
  }

  return attributes
}

export function serializeThingDefinitionAttributeDefinitionCheck (res: QueryExecResult[]): ThingDefinitionAttributeDefinitionCheck[] {
  const checks: ThingDefinitionAttributeDefinitionCheck[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      checks.push({
        id: (val as any)[0],
        severity: (val as any)[1],
        value: (val as any)[2],
        message: (val as any)[3],
        type: {
          id: (val as any)[4],
          value: (val as any)[5]
        },
        thing: {
          id: (val as any)[6],
          value: (val as any)[7],
          thingDefinition: maybeCreateThingDefinition((val as any)[8], (val as any)[9])
        },
        attribute: {
          id: (val as any)[10],
          value: (val as any)[11],
          attributeDefinition: {
            id: (val as any)[12],
            value: (val as any)[13],
            type: {
              id: (val as any)[14],
              value: (val as any)[15]
            },
            thingDefinition: {
              id: (val as any)[7],
              value: (val as any)[8]
            }
          },
          thing: {
            id: (val as any)[6],
            value: (val as any)[7],
            thingDefinition: maybeCreateThingDefinition((val as any)[8], (val as any)[9])
          }
        }
      })
    })
  }

  return checks
}

export function serializeState (res: QueryExecResult[]): State[] {
  const states: State[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      states.push({
        id: (val as any)[0],
        key: (val as any)[1],
        value: (val as any)[2]
      })
    })
  }

  return states
}

export function serializeAttributeType (res: QueryExecResult[]): AttributeType[] {
  const attributeTypes: AttributeType[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      attributeTypes.push({
        id: (val as any)[0],
        value: (val as any)[1]
      })
    })
  }

  return attributeTypes
}

export function serializeThingRelationships (res: QueryExecResult[]): ThingRelationship[] {
  const thingRelationships: ThingRelationship[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      thingRelationships.push({
        id: (val as any)[0],
        type: (val as any)[1],
        self: {
          id: (val as any)[2],
          value: (val as any)[3],
          thingDefinition: maybeCreateThingDefinition((val as any)[4], (val as any)[5])
        },
        other: {
          id: (val as any)[6],
          value: (val as any)[7],
          thingDefinition: maybeCreateThingDefinition((val as any)[8], (val as any)[9])
        }
      })
    })
  }

  return thingRelationships
}

export function serializeCustomAttributes (res: QueryExecResult[]): CustomAttribute[] {
  const customAttributes: CustomAttribute[] = []

  if (res.length === 1) {
    res[0].values.forEach(val => {
      customAttributes.push({
        id: (val as any)[0],
        label: (val as any)[1],
        value: (val as any)[2],
        type: {
          id: (val as any)[3],
          value: (val as any)[4]
        },
        thing: {
          id: (val as any)[7],
          value: (val as any)[8],
          thingDefinition: {
            id: (val as any)[5],
            value: (val as any)[6]
          }
        }
      })
    })
  }

  return customAttributes
}
