import React from 'react'

interface IconProps {
  className?: string
}

function LeftChevronIcon ({ className }: IconProps): JSX.Element | null {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path d="M206.7 464.6l-183.1-191.1C18.22 267.1 16 261.1 16 256s2.219-11.97 6.688-16.59l183.1-191.1c9.152-9.594 24.34-9.906 33.9-.7187c9.625 9.125 9.938 24.37 .7187 33.91L73.24 256l168 175.4c9.219 9.5 8.906 24.78-.7187 33.91C231 474.5 215.8 474.2 206.7 464.6z"/>
    </svg>
  )
}

LeftChevronIcon.defaultProps = {
  className: 'icon'
}

export default LeftChevronIcon
