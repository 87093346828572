import React from 'react'
import DB from '../../db'
import styled from 'styled-components'
import Button from '../Button'

import {
  ProjectSession,
  ProjectSolution
} from '../../db/types'

const ProjectContainer = styled.div`
  padding:
  ${props => props.theme.headerHeight * 2.5}px
  ${props => props.theme.grid.grid_32}px;
`

const ProjectContainerHeader = styled.div`
  padding-bottom: ${props => props.theme.grid.grid_16}px;
`

const ProjectFooter = styled.div`
  position: fixed;
  left: 50%;
  bottom: ${props => props.theme.grid.grid_24}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.grid.grid_16}px;
  width: 100%;
  max-width: ${props => props.theme.breakpoints.small}px;
  border: solid 1px ${props => props.theme.borderColor.soft};
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.small}px;
  box-shadow: ${props => props.theme.shadows.large};
  transform: translateX(-50%);
`

const StepCounter = styled.div`
`

interface OneProps {
  id: number
  db: DB
}

function One ({ db, id }: OneProps): JSX.Element | null {
  const [projectSolutions, setProjectSolutions] = React.useState<ProjectSolution[]>([])
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const [projectSession, setProjectSession] = React.useState<ProjectSession | undefined>(undefined)

  React.useEffect(() => {
    db.getProjectSession(id).then(projectSession => {
      if (projectSession === undefined) {
        return
      }

      setProjectSession(projectSession)
      db.getProjectSolutions(projectSession.project.id).then(projectSolutions => {
        setProjectSolutions(projectSolutions)
      }).catch(window.alert)
    }).catch(window.alert)
  }, [db, id, setProjectSession])

  return (
  <ProjectContainer>
    {projectSession !== undefined && projectSolutions.length !== 0 &&
      <div>
        <ProjectContainerHeader>
          <span>Current project</span>
          <h2>{projectSession.project.value}</h2>
        </ProjectContainerHeader>

        <div>
          {projectSolutions[currentStep].value}
        </div>

        <ProjectFooter>
            <Button
              onClick={() => { setCurrentStep(currentStep - 1) }}
              className={`${currentStep !== 0 ? '' : 'invisible'} step-previous`}
              text='Previous Step'
            />

            <StepCounter>
              Step {currentStep + 1} of {projectSolutions.length}
            </StepCounter>

            <Button
              onClick={() => { setCurrentStep(currentStep + 1) }}
              className={`${currentStep < (projectSolutions.length - 1) ? '' : 'invisible'} 'step-next'`}
              text='Next Step'
            />
        </ProjectFooter>
      </div>
    }
  </ProjectContainer>
  )
}

export default One
