import React from 'react'
import DB from '../../db'
import { useParams } from 'react-router-dom'
import One from './One'
import All from './All'

interface ProjectProps {
  db: DB
}

function Project ({ db }: ProjectProps): JSX.Element | null {
  const { id } = useParams()

  if (id !== undefined) {
    const parsedId = parseInt(id, 10)
    if (parsedId !== undefined) {
      return <One db={db} id={parsedId} />
    }
  }

  return <All db={db} />
}

export default Project
