import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
  text?: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
  className?: string
}

const ButtonComponent = styled.button`
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  text-align: center;
  padding: ${props => props.theme.grid.grid_12}px ${props => props.theme.grid.grid_16}px;
  background: ${props => props.theme.colors.green};
  border-radius: ${props => props.theme.borderRadius.default}px;
  cursor: pointer;
  transition: ${props => props.theme.transitions.quick};

  :hover {
    background: ${props => props.theme.colors.green_dark};
  }

  &.full {
    width: 100%;
  }

  &.half {
    width: 50%;
  }

  &.large {
    font-size: ${props => props.theme.text.size.large}px;
    padding: ${props => props.theme.grid.grid_24}px ${props => props.theme.grid.grid_32}px;
  }

  &.utility {
    color: ${props => props.theme.colors.black80};
    font-size: ${props => props.theme.text.size.small}px;
    padding: ${props => props.theme.grid.grid_8}px ${props => props.theme.grid.grid_12}px;
    background: ${props => props.theme.colors.white};

    &:hover {
      background: ${props => props.theme.colors.black10};
    }
  }

  &.delete {
    background: ${props => props.theme.colors.red};
  }

  &.green {
    background: ${props => props.theme.colors.green};
  }

  &.step-previous {
      color: ${props => props.theme.colors.text};
      background: ${props => props.theme.colors.shadow};
      outline: inset solid 2px ${props => props.theme.colors.shadow};
    }
  }
`

function Button ({ onClick, className, text, children }: ButtonProps): JSX.Element | null {
  return (
    <ButtonComponent onClick={onClick} className={className}>
      {text}
      {children}
    </ButtonComponent>
  )
}

export default Button
