import base from './base'
import {
  Attribute,
  AttributeDefinition,
  AttributeType,
  CustomAttribute,
  Project,
  ProjectAlternateValue,
  ProjectSession,
  ProjectSolution,
  State,
  Thing,
  ThingDefinition,
  ThingDefinitionAttributeDefinitionCheck,
  ThingRelationship,
  serializeAttributeDefinitions,
  serializeAttributeType,
  serializeAttributes,
  serializeCustomAttributes,
  serializeProject,
  serializeProjectAlternateValues,
  serializeProjectSessions,
  serializeProjectSolutions,
  serializeState,
  serializeThingDefinitionAttributeDefinitionCheck,
  serializeThingDefinitions,
  serializeThingRelationships,
  serializeThings
} from './types'

interface createThingArguments {
  value: string
  thingDefinitionId?: number
  room?: Thing
}

export default class db extends base {
  async getThings (typeFilter?: string): Promise<Thing[]> {
    const db = await this.sqlite

    if (typeFilter !== undefined) {
      return serializeThings(db.exec('SELECT * FROM thing_view WHERE thing_definition_value = (?)', [typeFilter]))
    } else {
      return serializeThings(db.exec('SELECT * from thing_view'))
    }
  }

  async getThing (thingDescription: string): Promise<Thing | undefined> {
    return serializeThings((await this.sqlite).exec('SELECT * FROM thing_view WHERE thing_value = (?)', [thingDescription]))[0]
  }

  async getThingRelationships (other?: number): Promise<ThingRelationship[]> {
    const db = await this.sqlite

    if (other !== undefined) {
      return serializeThingRelationships(db.exec('SELECT * FROM thing_relationship_view WHERE other_id = (?)', [other]))
    } else {
      return serializeThingRelationships(db.exec('SELECT * FROM thing_relationship_view'))
    }
  }

  async getThingDefinitions (): Promise<ThingDefinition[]> {
    return serializeThingDefinitions((await this.sqlite).exec('SELECT id, value FROM thing_definition'))
  }

  async getAttributeDefinitions (id: number): Promise<AttributeDefinition[]> {
    return serializeAttributeDefinitions((await this.sqlite).exec('SELECT * FROM thing_definition_attributes_view  WHERE thing_definition_id = (?)', [id]))
  }

  async getAttributes (id: number): Promise<Attribute[]> {
    return serializeAttributes((await this.sqlite).exec('SELECT * FROM thing_attribute_view WHERE thing_id = (?)', [id]))
  }

  async createAttribute (thing: number, attribute: number, value: string): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO attribute (thing, attribute_definition, value) VALUES (?,?,?)', [thing, attribute, value])
  }

  async getProjects (): Promise<Project[]> {
    return serializeProject((await this.sqlite).exec('SELECT * FROM projects_thing_view'))
  }

  async getProject (projectId: number): Promise<Project | undefined> {
    return serializeProject((await this.sqlite).exec('SELECT * FROM projects_thing_view WHERE project_id = (?)', [projectId]))[0]
  }

  async getProjectAlternateValues (): Promise<ProjectAlternateValue[]> {
    return serializeProjectAlternateValues((await this.sqlite).exec('SELECT id, value, project FROM project_alternate_value'))
  }

  async createThing ({ value, thingDefinitionId, room }: createThingArguments): Promise<void> {
    await this.insertThing(value, thingDefinitionId)

    if (room !== undefined) {
      await this.insertRelationship(room.id, await this.newestFromTable('thing'), 'child')
    }
  }

  async deleteThing (thingId: number): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'UPDATE thing SET deleted = TRUE WHERE id = (?)', [thingId])
  }

  async updateThing (thingId: number, value: string): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'UPDATE thing SET value = (?) WHERE id = (?)', [value, thingId])
  }

  async createProjectSession (projectId: number, thingId: number): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO project_session (project, thing) VALUES (?,?)', [projectId, thingId])
  }

  async getProjectSession (projectSessionId: number): Promise<ProjectSession | undefined> {
    return serializeProjectSessions((await this.sqlite).exec('SELECT * FROM project_sessions_view WHERE project_session_id = (?)', [projectSessionId]))[0]
  }

  async getProjectSessions (): Promise<ProjectSession[]> {
    return serializeProjectSessions((await this.sqlite).exec('SELECT * FROM project_sessions_view'))
  }

  async getProjectSolutions (projectId: number): Promise<ProjectSolution[]> {
    return serializeProjectSolutions((await this.sqlite).exec('SELECT * FROM project_solutions_view WHERE project_id = (?)', [projectId])
    )
  }

  async getThingDefinitionAttributeDefinitionChecks (): Promise<ThingDefinitionAttributeDefinitionCheck[]> {
    return serializeThingDefinitionAttributeDefinitionCheck((await this.sqlite).exec('SELECT * FROM thing_definition_attribute_definition_check_view'))
  }

  async getState (key: string): Promise<State | undefined> {
    return serializeState((await this.sqlite).exec('SELECT id, key, value FROM state'))[0]
  }

  async setState (key: string, value: string): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO state (key, value) VALUES (?,?)', [key, value])
  }

  async getAttributeTypes (): Promise<AttributeType[]> {
    return serializeAttributeType((await this.sqlite).exec('SELECT id, value FROM attribute_type'))
  }

  async createCustomAttribute (thing: number, type: number, label: string, value: string): Promise<void> {
    await this.writeAndSave(await this.sqlite, 'INSERT INTO custom_attribute (thing, type, label, value) VALUES (?, ?, ?, ?)', [thing, type, label, value])
  }

  async getCustomAttributes (thing: number): Promise<CustomAttribute[]> {
    return serializeCustomAttributes((await this.sqlite).exec('SELECT * FROM thing_custom_attribute_view WHERE thing_id = (?)', [thing]))
  }
}
