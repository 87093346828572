import React from 'react'

interface IconProps {
  className?: string
}

function HouseIcon ({ className }: IconProps): JSX.Element | null {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path d="M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L512 185V64c0-17.7-14.3-32-32-32H448c-17.7 0-32 14.3-32 32v36.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32V448c0 35.3 28.7 64 64 64H448.5c35.5 0 64.2-28.8 64-64.3l-.7-160.2h32z"/>
    </svg>
  )
}

HouseIcon.defaultProps = {
  className: 'icon'
}

export default HouseIcon
